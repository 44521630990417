@mixin heading {
 
        text-align: center;
        padding-bottom:40px;
        h1{
            font-size: 36px;
            font-weight: 700;
            position: relative;
            // text-transform: uppercase;
            span{
            color: $sec_color;
            }
        }
        /* === HEADING STYLE #1 === */
   
     h1:before {
        width: 28px;
        height: 5px;
        display: block;
        content: "";
        position: absolute;
        bottom: -2px;
        left: 50%;
        margin-left: -14px;
        background-color: $black;
    }
     h1:after {
        width: 100px;
        height: 1px;
        display: block;
        content: "";
        position: relative;
        margin-top: 25px;
        left: 50%;
        margin-left: -50px;
        background-color: $black;
    }
    
}
@import "../../Config/Variables.scss";
.contact{
    
    padding: 2rem 0;
    background-color:$pry-color;
    text-align: center;
    .bnr_info{
        h2{
            font-size: 14px;
            font-weight: 600;
            text-transform: uppercase;
            letter-spacing: 3px;
            padding-bottom: 10px;
        }
        h1{
            font-size: 48px;
            font-weight: 800;
            letter-spacing: 0.25px;
            padding-bottom: 1rem;
        }
        p{
            font-size: 14px;
            font-weight: 500;
            color: #6e6d6db1;
            margin: 0;
            a{
                color: #000000b1;
            }
        }
    }
}
.contact_forms{
    padding: 3rem 0;
    .wrap{
        display: flex;
        column-gap: 5rem;
    }
    .cont_info{
        width: 40%;
        h1{
            font-size: 24px;
            font-weight: 600;
            color: $pry_color;
            text-decoration: underline;
            padding: 20px 0;
        }
        h2{
            font-size: 20px;
            font-weight: 600;
            padding-bottom: 10px;
        }
        ul{
            .list_wrap{
                display: flex;
                align-items: flex-start;
                // padding-bottom: 1rem;
                i{
                    padding-right: 1rem;
                    color: #000000b1;
                    font-size: 18px;
                    padding-top: 0.3rem;
                }
                li{
                    list-style: none;
                    font-size: 18px;
                    line-height: 20px*1.5;
                    color: rgb(0, 0, 0);
                    font-weight: 600;
                    // padding-bottom: 1rem;
                }
                &:nth-child(4){
                    li{
                        width: 30%;
                    }
                }
            }
        }
    }
    form{
        width: 50%;
       .box{
        display: flex;
        flex-direction: column;
        padding-bottom: 2rem;
        P{
            color: red;
            margin: 0;
            font-size: 14px;
        }
        label{
            font-size: 18px ;
            font-weight: 600;
        }
        input{
            height: 50px;
            padding: 0 0.5rem;
        }
        textarea{
            height: 100px;
            padding: 0 0.5rem;

        }
       } 
       button{
        width: 100%;
        border: none;
        background-color: $sec_color;
        color: $white;
        height: 50px;
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0.25px;
       }
    }
}
@media screen and ( max-width:992px)  {
    .contact{
    
        padding: 1rem 0;
       background-color:$pry-color;
    
        text-align: center;
        .bnr_info{
            h1{
                font-size: 28px;
                font-weight: 800;
                letter-spacing: 0.25px;
                padding-bottom: 0.5rem;
            }
            p{
                margin: 0;
                font-size: 12px;
                font-weight: 500;
                color: #6e6d6db1;
                a{
                    color: #000000b1;
                }
            }
        }
    }
    .contact_forms{
        padding: 1rem 0 2rem;
        .wrap{
            display: flex;
            column-gap: 5rem;
            flex-direction: column;
        }
        .cont_info{
            width: 100%;
            padding-bottom: 2rem;
            h1{
                font-size: 18px;
                font-weight: 600;
                color: $pry_color;
                text-decoration: underline;
                padding: 10px 0 ;
            }
            h2{
                font-size: 16px;
                font-weight: 600;
                padding-bottom: 10px;
            }
            ul{
                .list_wrap{
                    display: flex;
                    align-items: flex-start;
                    // padding-bottom: 1rem;
                    i{
                        padding-right: 0.5rem;
                      
                        font-size: 12px;
                        padding-top: 0.3rem;
                    }
                    li{
                        list-style: none;
                        font-size: 12px;
                        line-height: 14px*1.5;
         
                        font-weight:600;
                        // padding-bottom: 1rem;
                    }
                    &:nth-child(4){
                        li{
                            width: 30%;
                        }
                    }
                }
            }
        }
        form{
            width: 100%;
           .box{
            display: flex;
            flex-direction: column;
            padding-bottom: 1rem;
            label{
                font-size: 14px ;
                font-weight: 600;
            }
            input{
                height: 40px;
                padding: 0 0.5rem;
            }
            textarea{
                height: 80px;
                padding: 0 0.5rem;
    
            }
           } 
           button{
            width: 100%;
            border: none;
            background-color: $sec_color;
            color: $white;
            height: 40px;
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 0.25px;
           }
        }
    }
}

@media screen and ( max-width:767px)  {
    .contact{
    
        padding: 1rem 0;
       background-color:$pry-color;
    
        text-align: center;
        .bnr_info{
            h1{
                font-size: 28px;
                font-weight: 800;
                letter-spacing: 0.25px;
                padding-bottom: 0.5rem;
            }
            p{
                margin: 0;
                font-size: 12px;
                font-weight: 500;
                color: #6e6d6db1;
                a{
                    color: #000000b1;
                }
            }
        }
    }
    .contact_forms{
        padding: 1rem 0 2rem;
        .wrap{
            display: flex;
            column-gap: 5rem;
            flex-direction: column;
        }
        .cont_info{
            width: 100%;
            padding-bottom: 2rem;
            h1{
                font-size: 18px;
                font-weight: 600;
                color: $pry_color;
                text-decoration: underline;
                padding: 10px 0 ;
            }
            h2{
                font-size: 16px;
                font-weight: 600;
                padding-bottom: 10px;
            }
            ul{
                .list_wrap{
                    display: flex;
                    align-items: flex-start;
                    // padding-bottom: 1rem;
                    i{
                        padding-right: 0.5rem;
                      
                        font-size: 12px;
                        padding-top: 0.3rem;
                    }
                    li{
                        list-style: none;
                        font-size: 12px;
                        line-height: 14px*1.5;
         
                        font-weight:600;
                        // padding-bottom: 1rem;
                    }
                    &:nth-child(4){
                        li{
                            width: 30%;
                        }
                    }
                }
            }
        }
        form{
            width: 100%;
           .box{
            display: flex;
            flex-direction: column;
            padding-bottom: 1rem;
            label{
                font-size: 14px ;
                font-weight: 600;
            }
            input{
                height: 40px;
                padding: 0 0.5rem;
            }
            textarea{
                height: 80px;
                padding: 0 0.5rem;
    
            }
           } 
           button{
            width: 100%;
            border: none;
            background-color: $sec_color;
            color: $white;
            height: 40px;
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 0.25px;
           }
        }
    }
}
@media screen and ( max-width:448px)  {
    .contact{
    
        padding: 1rem 0;
       background-color:$pry-color;
    
        text-align: center;
        .bnr_info{
            h1{
                font-size: 28px;
                font-weight: 800;
                letter-spacing: 0.25px;
                padding-bottom: 0.5rem;
            }
            p{
                margin: 0;
                font-size: 12px;
                font-weight: 500;
                color: #6e6d6db1;
                a{
                    color: #000000b1;
                }
            }
        }
    }
    .contact_forms{
        padding: 1rem 0 2rem;
        .wrap{
            display: flex;
            column-gap: 5rem;
            flex-direction: column;
        }
        .cont_info{
            width: 100%;
            padding-bottom: 2rem;
            h1{
                font-size: 18px;
                font-weight: 600;
                color: $pry_color;
                text-decoration: underline;
                padding: 10px 0 ;
            }
            h2{
                font-size: 16px;
                font-weight: 600;
                padding-bottom: 10px;
            }
            ul{
                .list_wrap{
                    display: flex;
                    align-items: flex-start;
                    // padding-bottom: 1rem;
                    i{
                        padding-right: 0.5rem;
                      
                        font-size: 12px;
                        padding-top: 0.3rem;
                    }
                    li{
                        list-style: none;
                        font-size: 12px;
                        line-height: 14px*1.5;
         
                        font-weight:600;
                        // padding-bottom: 1rem;
                    }
                    &:nth-child(4){
                        li{
                            width: 30%;
                        }
                    }
                }
            }
        }
        form{
            width: 100%;
           .box{
            display: flex;
            flex-direction: column;
            padding-bottom: 1rem;
            label{
                font-size: 14px ;
                font-weight: 600;
            }
            input{
                height: 40px;
                padding: 0 0.5rem;
            }
            textarea{
                height: 80px;
                padding: 0 0.5rem;
    
            }
           } 
           button{
            width: 100%;
            border: none;
            background-color: $sec_color;
            color: $white;
            height: 40px;
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 0.25px;
           }
        }
    }
}
@import "../../Config/Variables.scss";

.navbar.colorChange {
  position: fixed;
  width: 100%;
  animation: animed 0.6s ease-in-out;
  top: 0;
  background-color: $sec_color;
  z-index: 1000;
  box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.194);
}
@keyframes animed {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0%);
  }
}

.navbar{
    padding: 0.5rem 0;
    background-color:$sec_color;
    position: relative;
    border-bottom: 1px solid #0000001e;
    .nav_wrap{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .logo{
        width: 10%;
        img{
            width: 100%;
        }
    }
    .menu{ 
        ul{
            li{
                list-style: none;
                display: inline;
                padding-right: 1.5rem;
                height: 10%;
                a{
                    font-size: 16px;
                    line-height: 18px*1.5;
                    font-weight: 600;
                    color: $white;
                    letter-spacing: 0.2px;
                    font-family: 'Montserrat', sans-serif;
                    &:hover{
                        padding: 40px 0;
                        color: $pry-color;
                    }
                  }
                  .dropdown_content{
                      display: none;
                      position: absolute;
                      background-color: #f9f9f9;
                      box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
                      z-index: 1;  
                      top: 100%; 
                      border-top: 1px solid #0000001e;  
                      height: 100%; 
                      width: 20%;
                      text-align: left; 
                      padding-bottom: 15rem;
            

                          .info{
                              width: 100%;
                              text-align: start;
                              
                              h1{
                                  font-size: 24px;
                                  padding-bottom: 20px;
                                  line-height: 24px*1.5;
                                  font-weight: 600;
                                  text-transform: capitalize;
                              }
                          }
                          ul{
                              border-right: 1px solid #00000018;
                              text-align: left;
                              padding:2rem 0 ;
                              height: 100%;
                              li{
                                margin: 0 1rem;
                                  padding: 0.5rem ;
                                  display: flex;
                                  align-items: center;
                                  justify-content:space-between;
                                  border-bottom: 1px solid #00000018;
                                  .brp_menu{
                                      display: flex;
                                      align-items: center;
                                    
                                  img{
                                      width: 5%;
                                  }
                                  a{
                                      font-size: 14px;
                                      font-weight: 600;
                                      padding-left: 1rem;
                                      display: block;
                                      color: $black;
                                  }
                                  }
                                  i{
                                      padding-left: 2rem;
                                      text-align:end;
                                  }
                                  
                                  &:hover {
                                      cursor: pointer;
                                      background-color: #0000000a;
                                  }
                                  &:hover a{
                                      color: $pry_color;
                                      padding: 0;
                                      padding-left: 1rem;
                                  }
                              }        
                          
                      }
                          
                  }
                  &:hover .dropdown_content{ 
                    display: block;  

                  } 
                
            }
        }
    }
    .contactus{
        a{
            font-size: 16px;
            padding: 0.5rem 2rem;
            border-radius: 2px;
            border: 2px solid $pry_color;
            color: $white;
            font-weight: 600;
            letter-spacing: 0.5px;
            font-family: 'Montserrat', sans-serif;

            &:hover{
                background-color: $pry_color;
                transition: all .4s ease;
                color: $white;
            }
            i{
              padding-right: 0.5rem
            }
        }
    }
}
.mobile_nav {
    display: none;
  }
.toggle_menu {
    display: none;
 }
@media screen and (max-width:1380px) and (min-width: 992px) {
.navbar{
  padding: 1rem 0;
  position: relative;
  z-index: 4;
  width: 100%;
  top: 0;
  background-color:$sec_color;
  border-bottom: 1px solid #0000001e;
  &:hover{
      background-color: $white;
      transition: 0.4s ease-in-out;
  }
  &:hover .menu ul li a{
      color: #0000009d;
      cursor: pointer;
  }
  &:hover .contactus  a{
      color:$black;
  }

  .nav_wrap{
      display: flex;
      align-items: center;
      justify-content: space-between;
  }
  .logo{
      width: 10%;
      img{
          width: 100%;
      }
  }
  .menu{ 
      ul{
          li{
              
              list-style: none;
              display: inline;
              padding-right: 1rem;
              height: 10%;
              a{
                  font-size: 14px;
                  line-height: 18px*1.5;
                  font-weight: 600;
                  color: $white;
                  letter-spacing: 0.2px;
                  font-family: 'Montserrat', sans-serif;

                  opacity: 0.9;
                  &:hover{
                      padding: 40px 0;
                      color: $black;
                  }
                  
              }
              .dropdown_content{
                  display: none;
                  position: absolute;
                  background-color: #f9f9f9;
                  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
                  z-index: 1;  
                  top: 100%; 
                  border-top: 1px solid #0000001e;  
                  height: 100%; 
                  width: 30%;
                  text-align: left; 
                  padding-bottom: 15rem;
        

                      .info{
                          width: 100%;
                          text-align: start;
                          
                          h1{
                              font-size: 24px;
                              padding-bottom: 20px;
                              line-height: 24px*1.5;
                              font-weight: 600;
                              text-transform: capitalize;
                          }
                      }
                      ul{
                          border-right: 1px solid #00000018;
                          text-align: left;
                          padding:2rem 0 ;
                          height: 100%;
                          li{
                            margin: 0 1rem;
                              padding: 0.5rem ;
                              display: flex;
                              align-items: center;
                              justify-content:space-between;
                              border-bottom: 1px solid #00000018;
                              .brp_menu{
                                  display: flex;
                                  align-items: center;
                                  
                              img{
                                  width: 5%;
                              }
                              a{
                                  font-size: 12px;
                                  font-weight: 600;
                                  padding-left: 1rem;
                                  display: block;
                              }
                              }
                              i{
                                  padding-left: 2rem;
                                  text-align:end;
                              }
                              
                              &:hover {
                                  cursor: pointer;
                                  background-color: #0000000a;
                              }
                              &:hover a{
                                  color: $pry_color;
                                  padding: 0;
                                  padding-left: 1rem;
                              }
                          }        
                      
                  }
                      
              }
              &:hover{
                  .dropdown_content{
                      display: block; 
                  }
              }
          }
      }
  }
  .contactus{
      a{
          font-size: 14px;
          padding: 0.5rem 2rem;
          border-radius: 3px;
          border: 2px solid $pry_color;
          color: $white;
          font-weight: 600;
          letter-spacing: 0.5px;
          font-family: 'Montserrat', sans-serif;

          &:hover{
              background-color: $pry_color;
              transition: all .4s ease;
              color: $white;
          }
      }
  }
}
}

@media screen and (max-width: 991px) and (min-width:449px) {
.navbar {
  display: none;
}

.mobile_nav.colorChange {
  position: fixed;
  width: 100%;
  animation: animed 0.6s ease-in-out;
  top: 0;
  background-color:$sec_color;
  z-index: 6;
  box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.39);
}
@keyframes animed {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0%);
  }
}

.mobile_nav {
  display: block;
  position: relative;
  width: 100%;
  top: 0;
  z-index: 6;
  background-color: $sec_color;
  .nav_bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 0;
    .logo {
      width: 15%;
      img {
        width: 100%;
      }
    }
    .toggle_button {
      button {
        position: relative;
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        border: none;
        background-color: transparent;
        display: block;
        z-index: 4;

        &.menuicons span {
          transform: translateX(60px);
        }

        &.menuicons span:nth-child(1) {
          transform: translateY(0px);
          width: 20px;
          transform: rotate(50deg);
        }

        &.menuicons span:nth-child(2) {
          transform: translateY(0px);
          width: 20px;
          transform: rotate(128deg);
        }

        & span {
          position: absolute;
          width: 30px;
          height: 2.5px;
          background-color: $pry_color;
          border-radius: 4px;
          transition: 0.5s;
          right: 15px;

          &:nth-child(1) {
            transform: translateY(-8px);
            width: 20px;
            left: 15px;
          }

          &:nth-child(2) {
            transform: translateY(8px);
            width: 20px;
            left: 15px;
          }
        }
      }
      .menu-one {
        display: none;
      }
    }
  }
}
.toggle_menu {
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  content: "";
  z-index: 5;
  box-shadow: 1px 10px 11px 3px #00000017;
  font-family: "Kanit", sans-serif;
  background-color:$white;
  margin: 0;
  transform: translate(110%);
  padding-top: 6rem;
  overflow: hidden;
  overflow-y: scroll;
  height: auto;
  .menus {
    ul {
      padding:  0;
      .dropdown {
        padding: 0;
      }
      li {
        display: block;
        text-align: left;
        margin: 0rem 2rem;
        padding: 0.5rem;
        border-bottom: 1px dotted rgb(199, 198, 198);
        button{
          border: none;
          background-color: transparent;
          color: $sec_color;
          font-weight: 600;
          font-size: 0.8em;
        }

        .dropdown-toggle {
          position: relative;
          &::after {
            display: inline-block;
            margin-left: 0;
            vertical-align: 0.255em;
            content: "";
            border-top: 0.3em solid;
            border-right: 0.3em solid transparent;
            border-bottom: 0;
            border-left: 0.3em solid transparent;
            position: absolute;
            top: 50%;
            right: 0;
          }
        }
        a {
          font-size: 0.8em;
          font-weight: 600;
          letter-spacing: 0.50px;
          // font-family: "Kanit", sans-serif;  
          line-height: 2rem;
          text-decoration: none;
          color: $sec_color;
          text-transform: capitalize;

          &.active {
            color: $sec_color;
          }
        }
        ul {
          background-color: transparent;
          border: none;
          li {
            padding: 0;
            background-color: $sec_color;
            margin: 0rem;
            padding: 0 1rem;
          }
        }
      }
    }
  }
  .bottom {
    padding-top: 3rem;
  }
  .add {
    position: relative;
    top: 0;
    bottom: 0;
    background-color: transparet;
    height: auto;
    color: $pry_color;
    padding: 2rem 2rem 0;
    text-align: center;
    i {
      font-size: 0.9rem;

      padding-bottom: 1rem;
    }
    h1 {
      font-size: 1rem;
      font-weight: 600;
      padding-bottom: 0.5rem;
      font-family: "Kanit", sans-serif;
    }
    h6 {
      padding-bottom: 2rem;
      border-bottom: 1px solid #f3f0f040;
      font-size: 0.8rem;
      opacity: 0.5;
      font-family: "Kanit", sans-serif;
    }
    p {
      font-size: 0.8rem;
      opacity: 0.5;
      padding-bottom: 1rem;
    }
    ul {
      li {
        border: none;
        display: inline;
        padding: 0;
        margin: 0 0.5rem;
        background-color: transparent;
        a {
          color: $pry_color;
          font-size: 1.2rem;
          opacity: 0.5;
        }
      }
    }
  }
}
.actives {
  transform: translate(0%);
  transition: 0.8s;
}
}
@media screen and (max-width: 448px) and (min-width:375px) {
  .navbar {
    display: none;
  }

  .mobile_nav.colorChange {
    position: fixed;
    width: 100%;
    animation: animed 0.6s ease-in-out;
    top: 0;
    background-color:$sec_color;
    z-index: 6;
    box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.39);
  }
  @keyframes animed {
    0% {
      transform: translateY(-100%);
    }
    100% {
      transform: translateY(0%);
    }
  }

  .mobile_nav {
    display: block;
    position: relative;
    width: 100%;
    top: 0;
    z-index: 6;
    background-color: $sec_color;
    .nav_bar {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 0.5rem 0;
      .logo {
        width: 25%;
        img {
          width: 100%;
        }
      }
      .toggle_button {
        button {
          position: relative;
          width: 50px;
          height: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          border: none;
          background-color: transparent;
          display: block;
          z-index: 4;

          &.menuicons span {
            transform: translateX(60px);
          }

          &.menuicons span:nth-child(1) {
            transform: translateY(0px);
            width: 20px;
            transform: rotate(50deg);
          }

          &.menuicons span:nth-child(2) {
            transform: translateY(0px);
            width: 20px;
            transform: rotate(128deg);
          }

          & span {
            position: absolute;
            width: 30px;
            height: 2.5px;
            background-color: $pry_color;
            border-radius: 4px;
            transition: 0.5s;
            right: 15px;

            &:nth-child(1) {
              transform: translateY(-8px);
              width: 20px;
              left: 15px;
            }

            &:nth-child(2) {
              transform: translateY(8px);
              width: 20px;
              left: 15px;
            }
          }
        }
        .menu-one {
          display: none;
        }
      }
    }
  }
  .toggle_menu {
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    content: "";
    z-index: 5;
    box-shadow: 1px 10px 11px 3px #00000017;
    font-family: "Kanit", sans-serif;
    background-color:$white;
    margin: 0;
    transform: translate(110%);
    padding-top: 6rem;
    overflow: hidden;
    overflow-y: scroll;
    height: auto;
    .menus {
      ul {
        padding:  0;
        .dropdown {
          padding: 0;
        }
        li {
          display: block;
          text-align: left;
          margin: 0rem 2rem;
          padding: 0.5rem;
          border-bottom: 1px dotted rgb(199, 198, 198);
          button{
            border: none;
            background-color: transparent;
            color: $sec_color;
            font-weight: 600;
            font-size: 0.8em;
          }

          .dropdown-toggle {
            position: relative;
            &::after {
              display: inline-block;
              margin-left: 0;
              vertical-align: 0.255em;
              content: "";
              border-top: 0.3em solid;
              border-right: 0.3em solid transparent;
              border-bottom: 0;
              border-left: 0.3em solid transparent;
              position: absolute;
              top: 50%;
              right: 0;
            }
          }
          a {
            font-size: 0.8em;
            font-weight: 600;
            letter-spacing: 0.50px;
            // font-family: "Kanit", sans-serif;  
            line-height: 2rem;
            text-decoration: none;
            color: $sec_color;
            text-transform: capitalize;

            &.active {
              color: $sec_color;
            }
          }
          ul {
            background-color: transparent;
            border: none;
            li {
              padding: 0;
              background-color: $sec_color;
              margin: 0rem;
              padding: 0 1rem;
            }
          }
        }
      }
    }
    .bottom {
      padding-top: 3rem;
    }
    .add {
      position: relative;
      top: 0;
      bottom: 0;
      background-color: transparet;
      height: auto;
      color: $pry_color;
      padding: 2rem 2rem 0;
      text-align: center;
      i {
        font-size: 0.9rem;

        padding-bottom: 1rem;
      }
      h1 {
        font-size: 1rem;
        font-weight: 600;
        padding-bottom: 0.5rem;
        font-family: "Kanit", sans-serif;
      }
      h6 {
        padding-bottom: 2rem;
        border-bottom: 1px solid #f3f0f040;
        font-size: 0.8rem;
        opacity: 0.5;
        font-family: "Kanit", sans-serif;
      }
      p {
        font-size: 0.8rem;
        opacity: 0.5;
        padding-bottom: 1rem;
      }
      ul {
        li {
          border: none;
          display: inline;
          padding: 0;
          margin: 0 0.5rem;
          background-color: transparent;
          a {
            color: $pry_color;
            font-size: 1.2rem;
            opacity: 0.5;
          }
        }
      }
    }
  }
  .actives {
    transform: translate(0%);
    transition: 0.8s;
  }
}

@media screen and (max-width: 374px) and (min-width:320px) {
  .navbar {
    display: none;
  }

  .mobile_nav.colorChange {
    position: fixed;
    width: 100%;
    animation: animed 0.6s ease-in-out;
    top: 0;
    background-color:$sec_color;
    z-index: 6;
    box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.39);
  }
  @keyframes animed {
    0% {
      transform: translateY(-100%);
    }
    100% {
      transform: translateY(0%);
    }
  }

  .mobile_nav {
    display: block;
    position: relative;
    width: 100%;
    top: 0;
    z-index: 6;
    background-color: $sec_color;
    .nav_bar {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 0.5rem 0;
      .logo {
        width: 25%;
        img {
          width: 100%;
        }
      }
      .toggle_button {
        button {
          position: relative;
          width: 50px;
          height: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          border: none;
          background-color: transparent;
          display: block;
          z-index: 4;

          &.menuicons span {
            transform: translateX(60px);
          }

          &.menuicons span:nth-child(1) {
            transform: translateY(0px);
            width: 20px;
            transform: rotate(50deg);
          }

          &.menuicons span:nth-child(2) {
            transform: translateY(0px);
            width: 20px;
            transform: rotate(128deg);
          }

          & span {
            position: absolute;
            width: 30px;
            height: 2.5px;
            background-color: $pry_color;
            border-radius: 4px;
            transition: 0.5s;
            right: 15px;

            &:nth-child(1) {
              transform: translateY(-8px);
              width: 20px;
              left: 15px;
            }

            &:nth-child(2) {
              transform: translateY(8px);
              width: 20px;
              left: 15px;
            }
          }
        }
        .menu-one {
          display: none;
        }
      }
    }
  }
  .toggle_menu {
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    content: "";
    z-index: 5;
    box-shadow: 1px 10px 11px 3px #00000017;
    font-family: "Kanit", sans-serif;
    background-color:$white;
    margin: 0;
    transform: translate(110%);
    padding-top: 6rem;
    overflow: hidden;
    overflow-y: scroll;
    height: auto;
    .menus {
      ul {
        padding:  0;
        .dropdown {
          padding: 0;
        }
        li {
          display: block;
          text-align: left;
          margin: 0rem 2rem;
          padding: 0.5rem;
          border-bottom: 1px dotted rgb(199, 198, 198);
          button{
            border: none;
            background-color: transparent;
            color: $sec_color;
            font-weight: 600;
            font-size: 0.8em;
          }

          .dropdown-toggle {
            position: relative;
            &::after {
              display: inline-block;
              margin-left: 0;
              vertical-align: 0.255em;
              content: "";
              border-top: 0.3em solid;
              border-right: 0.3em solid transparent;
              border-bottom: 0;
              border-left: 0.3em solid transparent;
              position: absolute;
              top: 50%;
              right: 0;
            }
          }
          a {
            font-size: 0.8em;
            font-weight: 600;
            letter-spacing: 0.50px;
            // font-family: "Kanit", sans-serif;  
            line-height: 2rem;
            text-decoration: none;
            color: $sec_color;
            text-transform: capitalize;

            &.active {
              color: $sec_color;
            }
          }
          ul {
            background-color: transparent;
            border: none;
            li {
              padding: 0;
              background-color: $sec_color;
              margin: 0rem;
              padding: 0 1rem;
            }
          }
        }
      }
    }
    .bottom {
      padding-top: 3rem;
    }
    .add {
      position: relative;
      top: 0;
      bottom: 0;
      background-color: transparet;
      height: auto;
      color: $pry_color;
      padding: 2rem 2rem 0;
      text-align: center;
      i {
        font-size: 0.9rem;

        padding-bottom: 1rem;
      }
      h1 {
        font-size: 1rem;
        font-weight: 600;
        padding-bottom: 0.5rem;
        font-family: "Kanit", sans-serif;
      }
      h6 {
        padding-bottom: 2rem;
        border-bottom: 1px solid #f3f0f040;
        font-size: 0.8rem;
        opacity: 0.5;
        font-family: "Kanit", sans-serif;
      }
      p {
        font-size: 0.8rem;
        opacity: 0.5;
        padding-bottom: 1rem;
      }
      ul {
        li {
          border: none;
          display: inline;
          padding: 0;
          margin: 0 0.5rem;
          background-color: transparent;
          a {
            color: $pry_color;
            font-size: 1.2rem;
            opacity: 0.5;
          }
        }
      }
    }
  }
  .actives {
    transform: translate(0%);
    transition: 0.8s;
  }
}
@import "../../Config/Variables.scss";
@import "../../Config/Mixin.scss";
.about{
    padding: 2rem 0 1rem;
    background-color:$pry-color;

    text-align: center;
    .bnr_info{
        h2{
            text-transform: uppercase;
            letter-spacing: 3px;
            font-size: 14px;
            font-weight: 600;
            padding-bottom: 10px;
        }
        h1{
            font-size: 48px;
            font-weight: 800;
            letter-spacing: 0.25px;
            padding-bottom: 1rem;
        }
        p{
            font-size: 14px;
            font-weight: 500;
            color: #6e6d6db1;
            a{
                color: #000000b1;
            }
        }
    }
}
.about_info{
    padding: 2rem 0 0;
    background-color: #fdb61c20;
    .wrap{
        display: flex;
        justify-content: space-evenly;
        column-gap: 0rem;
    }
    .items{
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        column-gap: 0;
        padding: 40px 0 0;
        width: 30%;
        img{
            width:80%;
            // height: 300px;

        }
        .info{
            padding: 20px 0;
            h4{
                font-size:28px;
                font-weight: 700;
                color: $black;
                padding-bottom: 20px;
                letter-spacing: 0.25px;
            }
            p{
                font-size: 16px;
                line-height: 16px*1.5;
                letter-spacing: 0.2px;
                padding-right: 6rem;
                font-weight: 600;
            }

        }
    }
    
}
.why_qrm{
    padding: 3rem 0 2rem;
    position: relative;
    background-color: $pry-color;


    .heading{
        @include heading;
        h1::before{
            background-color: $black;
        }
        h1::after{
            background-color: $black;
        }
      }
  
    .wrap{
        display: flex;
        grid-template-columns: repeat(3,1fr);
        align-items: self-start;
        column-gap: 1rem;
        justify-content: space-around;
      
        .item{
            text-align: inherit;
            width: 70%;
            img{
                border-radius: 10px;
                box-shadow: 10px 10px 20px #00000017;
                width: 85%;
            }
            h2{
                font-size: 20px;
                font-weight: 700;
                letter-spacing: 0.25px;
                line-height: 28px*1.2;
                padding-bottom: 25px;
                padding-top: 20px;
                color: $sec_color;
                text-align: left;
            }
            ul{
                width: 85%;
                li{
                    list-style: none;
                    text-align: start;
                    display: flex;
                    align-items: flex-start;
                    line-height: 16px*1.5;
                    font-size: 16px;
                    font-weight: 600;
                    padding-bottom: 1rem
                    ;
                    i{
                        padding-top: 0.2rem;
                        padding-right: 1rem;
                    }
                }
            }
    }
}
}
.team{
    padding: 2rem 0;
    background-color:#fdb61c20;

    .heading{

        h1{
        font-size: 32px;
        line-height: 32px*1.2;
        letter-spacing: 0.25px;
        text-align: center;
        font-weight: 700;
        padding-bottom: 30px ;
        }
       
    }
    .team_subheadig{
        padding-left: 1rem;
        ul{
            text-align: center;
            li{
                text-align: left;
                list-style: none;
                font-size: 18px;
                line-height: 18px*1.8;
                font-weight: 600;
                img{
                    width: 2%;
                }
            }
        }
    }
    .wrap{
        margin-bottom: 2rem;
        display: grid;
        grid-template-columns: repeat(3,1fr);
        column-gap: 2rem;
        .card{
            box-shadow: 0px 0px 20px #00000015 ;
            padding: 1rem;
            border-radius: 5px;
            background-color: $white;
            display: flex;
            column-gap: 2rem;
            border-top: 3px solid $pry-color;
          
            .card_body{
                padding: 1rem 0;
                h2{
                    font-size: 24px;
                    padding-bottom: 1rem;
                    font-weight: 700;
                    letter-spacing: 0.25px;
                }
                h3{
                    font-size: 16px;
                    font-weight: 600;
                    opacity: 1;
                    color: $sec_color;
                    padding-bottom: 1.5rem;

                }
                ul{
                    li{
                        list-style: none;
                        display: flex;
                        align-items: flex-start;
                        padding-bottom: 1rem;
                        p{
                            margin: 0;
                            padding-left: 0.5rem;
                            font-size: 16px; 
                            font-weight: 600;
                        }
                        i{
                            padding-top: 3px;
                        }
                        
                    }
                }
                p{
                    font-size: 14px;
                }
            }
            &:hover{
                transform: translateY(-10px);
            }
        }
    }
   
}

@media screen and (max-width:1280px) and (min-width:991px) {
    .about{
        padding: 1rem 0;
        background-color:$pry-color;
        text-align: center;
        .bnr_info{
            h1{
                font-size: 28px;
                font-weight: 800;
                letter-spacing: 0.25px;
                padding-bottom: 0.5rem;
            }
            p{
                font-size: 12px;
                font-weight: 500;
                margin: 0;
                color: #6e6d6db1;
                a{
                    color: #000000b1;
                }
            }
        }
    }
    .about_info{
        padding: 1rem 0;
        .wrap{
            display: grid;
            grid-template-columns:  1fr 1fr;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;
            column-gap: 0;
        }
        .items{
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            column-gap: 1rem;
            padding: 10px 0;
            width: 70%;
            img{
                width: 100%;
            }
            .info{
                padding: 20px 0 0;
                h4{
                    font-size:24px;
                    font-weight: 700;
                    color: $black;
                    padding-bottom: 10px;
                    letter-spacing: 0.25px;
                }
                p{
                    font-size: 12px;
                    line-height: 12px*1.5;
                    font-weight: 600;
                    letter-spacing: 0.2px;
                    opacity: 1;
                    padding-right: 0rem;
                }
    
            }
        }
        
    }
    .why_qrm{
        padding: 3rem 0 2rem;
        position: relative;
        background-color: $pry-color;
    
    
        .heading{
            @include heading;
            h1::before{
                background-color: $black;
            }
            h1::after{
                background-color: $black;
            }
          }
      
        .wrap{
            display: grid;
            grid-template-columns: repeat(2,1fr);
            align-items: self-start;
            column-gap: 1rem;
            row-gap: 2rem;
            justify-content: space-around;
          
            .item{
                text-align: inherit;
                width: 100%;
                img{
                    border-radius: 10px;
                    box-shadow: 10px 10px 20px #00000017;
                    width: 80%;
                }
                h2{
                    font-size: 16px;
                    font-weight: 700;
                    letter-spacing: 0.25px;
                    line-height: 16px*1.2;
                    padding-bottom: 18px;
                    padding-top: 20px;
                    color: $sec_color;
                    text-align: left;
                }
                ul{
                    width: 85%;
                    li{
                        list-style: none;
                        text-align: start;
                        display: flex;
                        align-items: flex-start;
                        line-height: 12px*1.5;
                        font-size: 12px;
                        font-weight: 600;
                        padding-bottom: 1rem
                        ;
                        i{
                            padding-top: 0.2rem;
                            padding-right: 1rem;
                        }
                    }
                }
        }
    }
    }
    .team{
        padding: 1rem 0;
        .heading{
            h1{
            font-size: 24px;
            line-height: 32px*1.2;
            letter-spacing: 0.25px;
            text-align: center;
            font-weight: 700;
            padding-bottom: 10px ;
            }
        }
        .wrap{
            display: grid;
            grid-template-columns: repeat(2,1fr);
            column-gap: 2rem;
            row-gap: 2rem;
            .card{
                box-shadow: 0px 0px 20px #00000015 ;
                padding: 1rem;
                border-radius: 5px;
                background-color: $white;
                img{
                    width: 100%;
                    height: 100px;
                }
                .card_body{
                    padding: 1rem 0;
                    h2{
                        font-size: 18px;
                        padding-bottom: 0.5rem;
                        font-weight: 700;
                        letter-spacing: 0.25px;
                    }
                    h3{
                        font-size: 14px;
                        font-weight: 600;
                        opacity: 0.7;
                        color: $black;
                        padding-bottom: 0.5rem;
    
                    }
                    ul{
                        li{

                            p{
                                font-size: 12px;
                                font-weight: 600;
                            }
                        }
                    }
                }
            }
        }
        .team_subheadig{
            padding-left: 0.5rem;
            ul{
                text-align: center;
                
                li{
                    text-align: left;
                    list-style: none;
                    font-size: 14px;
                    padding-bottom: 1rem;
                    font-weight: 600;
                    line-height: 12px*1.5;
                    img{
                        width: 3%;
                    }
                }
            }
        }
    }
}

@media screen and (max-width:992px) and (min-width:768px) {
    .about{
        padding: 1rem 0;
        background-color:$pry-color;
        text-align: center;
        .bnr_info{
            h1{
                font-size: 28px;
                font-weight: 800;
                letter-spacing: 0.25px;
                padding-bottom: 0.5rem;
            }
            p{
                font-size: 12px;
                font-weight: 500;
                margin: 0;
                color: #6e6d6db1;
                a{
                    color: #000000b1;
                }
            }
        }
    }
    .about_info{
        padding: 1rem 0;
        .wrap{
            display: grid;
            grid-template-columns:  1fr 1fr;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            column-gap: 0;
        }
        .items{
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            column-gap: 1rem;
            padding: 10px 0;
            width: 70%;
            img{
                width: 100%;
            }
            .info{
                padding: 20px 0 0;
                h4{
                    font-size:24px;
                    font-weight: 700;
                    color: $black;
                    padding-bottom: 10px;
                    letter-spacing: 0.25px;
                }
                p{
                    font-size: 12px;
                    line-height: 12px*1.5;
                    font-weight: 600;
                    letter-spacing: 0.2px;
                    opacity: 1;
                    padding-right: 0rem;
                }
    
            }
        }
        
    }
    .why_qrm{
        padding: 3rem 0 2rem;
        position: relative;
        background-color: $pry-color;
    
    
        .heading{
            @include heading;
            h1::before{
                background-color: $black;
            }
            h1::after{
                background-color: $black;
            }
          }
      
        .wrap{
            display: grid;
            grid-template-columns: repeat(2,1fr);
            align-items: self-start;
            column-gap: 1rem;
            row-gap: 2rem;
            justify-content: space-around;
          
            .item{
                text-align: inherit;
                width: 100%;
                img{
                    border-radius: 10px;
                    box-shadow: 10px 10px 20px #00000017;
                    width: 80%;
                }
                h2{
                    font-size: 16px;
                    font-weight: 700;
                    letter-spacing: 0.25px;
                    line-height: 16px*1.2;
                    padding-bottom: 18px;
                    padding-top: 20px;
                    color: $sec_color;
                    text-align: left;
                }
                ul{
                    width: 85%;
                    li{
                        list-style: none;
                        text-align: start;
                        display: flex;
                        align-items: flex-start;
                        line-height: 12px*1.5;
                        font-size: 12px;
                        font-weight: 600;
                        padding-bottom: 1rem
                        ;
                        i{
                            padding-top: 0.2rem;
                            padding-right: 1rem;
                        }
                    }
                }
        }
    }
    }
    .team{
        padding: 1rem 0;
        .heading{
            h1{
            font-size: 24px;
            line-height: 32px*1.2;
            letter-spacing: 0.25px;
            text-align: center;
            font-weight: 700;
            padding-bottom: 10px ;
            }
        }
        .wrap{
            display: grid;
            grid-template-columns: repeat(2,1fr);
            column-gap: 2rem;
            row-gap: 2rem;
            .card{
                box-shadow: 0px 0px 20px #00000015 ;
                padding: 1rem;
                border-radius: 5px;
                background-color: $white;
                img{
                    width: 100%;
                    height: 100px;
                }
                .card_body{
                    padding: 1rem 0;
                    h2{
                        font-size: 18px;
                        padding-bottom: 0.5rem;
                        font-weight: 700;
                        letter-spacing: 0.25px;
                    }
                    h3{
                        font-size: 14px;
                        font-weight: 600;
                        opacity: 0.7;
                        color: $black;
                        padding-bottom: 0.5rem;
    
                    }
                    ul{
                        li{

                            p{
                                font-size: 12px;
                                font-weight: 600;
                            }
                        }
                    }
                }
            }
        }
        .team_subheadig{
            padding-left: 0.5rem;
            ul{
                text-align: center;
                
                li{
                    text-align: left;
                    list-style: none;
                    font-size: 14px;
                    padding-bottom: 1rem;
                    font-weight: 600;
                    line-height: 12px*1.5;
                    img{
                        width: 3%;
                    }
                }
            }
        }
    }
}
// @media screen and (max-width:767px) and (min-width:449px) {
//     .about{
//         padding: 1rem 0;
//        background-color:$pry-color;
//         text-align: center;
//         .bnr_info{
//             h1{
//                 font-size: 24px;
//                 font-weight: 800;
//                 letter-spacing: 0.25px;
//                 padding-bottom: 0.5rem;
//             }
//             p{
//                 font-size: 12px;
//                 font-weight: 500;
//                 margin: 0;
//                 color: #6e6d6db1;
//                 a{
//                     color: #000000b1;
//                 }
//             }
//         }
//     }
//     .about_info{
//         padding: 1rem 0;
//         .wrap{
//             display: flex;
//             justify-content: center;
//             align-items: center;
//             flex-direction: column;
//             column-gap: 0;
//         }
//         .items{
//             display: flex;
//             align-items: flex-start;
//             flex-direction: column;
//             column-gap: 1rem;
//             padding: 10px 0;
//             width: 80%;
//             img{
//                 width: 70%;
//             }
//             .info{
//                 padding: 20px 0 0;
//                 // text-align: center;
//                 h4{
//                     font-size:24px;
//                     font-weight: 700;
//                     color: $black;
//                     padding-bottom: 10px;
//                     letter-spacing: 0.25px;
//                 }
//                 p{
//                     font-size: 12px;
//                     line-height: 12px*1.5;
//                     font-weight: 500;
//                     letter-spacing: 0.2px;
//                     opacity: 0.8;
//                     padding-right: 0rem;
//                 }
    
//             }
//         }
        
//     }
//     .why_qrm{
//         padding: 1rem 0;
//         position: relative;
//         .heading{
//             padding-bottom: 10px;
//             h1{
//                 font-size: 1.3rem;
//                 &::after{
//                     margin-top: 6px;
//                 }
//             }
//         }  
      
//         .wrap{
//             display: flex;
//             align-items: self-start;
//             flex-direction: column;
//             column-gap: 3rem;
//             justify-content: space-around;
//             row-gap: 1rem;
//             .item{
//                 text-align: left;
//                 width: 100%;
//                 background-color: $white;
//                 padding: 1rem;

                
//                 img{
//                     border-radius: 0px;
//                     box-shadow: 10px 10px 20px #00000017;
//                     width: 80%;
//                     // height: 100px;
//                 }
//                 h2{
//                     font-size: 16px;
//                     font-weight: 700;
//                     letter-spacing: 0.25px;
//                     line-height: 28px*1.2;
//                     padding-bottom: 10px;
//                     padding-top: 10px;
//                     color: $sec_color;
//                     text-align: left;
//                 }
//         ul{
//            li{
//             list-style: none;
//             text-align: start;
//             display: flex;
//             align-items: flex-start;
//             font-size: 12px;
//             line-height: 12px*1.5;
//             padding-bottom: 0.5rem
//             ;
//             i{
//                 padding-top: 0.2rem;
//                 padding-right: 1rem;
//             }
//            }
//         }
//         }
//     }
//     }
//     .team{
//         padding: 1rem 0;

    
//         .heading{
//             h1{
//             font-size: 24px;
//             line-height: 32px*1.2;
//             letter-spacing: 0.25px;
//             text-align: center;
//             font-weight: 700;
//             padding-bottom: 10px ;
//             }
//         }
//         .team_subheadig{
//             padding-left: 1rem;
//             ul{
//                 text-align: center;
//                 li{
//                     text-align: left;
//                     list-style: none;
//                     font-size: 12px;
//                     padding-bottom: 0.5rem;
//                     img{
//                         width: 5%;
//                     }
//                 }
//             }
//         }
//         .wrap{
//             display: grid;
//             grid-template-columns: repeat(2,1fr);
//             column-gap: 2rem;
//             row-gap: 2rem;
//             padding-bottom: 1.5rem;
//             .card{
//                 box-shadow: 0px 0px 20px #00000015 ;
//                 padding: 1rem;
//                 border-radius: 5px;
//                 background-color: $white;
//                 img{
//                     width: 100%;
//                     height: 100px;
//                 }
//                 .card_body{
//                     padding: 1rem 0;
//                     h2{
//                         font-size: 18px;
//                         padding-bottom: 0.5rem;
//                         font-weight: 700;
//                         letter-spacing: 0.25px;
//                     }
//                     h3{
//                         font-size: 14px;
//                         font-weight: 500;
//                         padding-bottom: 0.5rem;
    
//                     }
//                     p{
//                         font-size: 14px;
//                     }
//                 }
//             }
//         }
//     }
// }
@media screen and (max-width:767px) and (min-width:320px) {
    .about{
        padding: 1rem 0;
       background-color:$pry-color;
        text-align: center;
        .bnr_info{
            h1{
                font-size: 24px;
                font-weight: 800;
                letter-spacing: 0.25px;
                padding-bottom: 0.5rem;
            }
            p{
                font-size: 12px;
                font-weight: 500;
                margin: 0;
                color: #6e6d6db1;
                a{
                    color: #000000b1;
                }
            }
        }
    }
    .about_info{
        padding: 1rem 0;
        .wrap{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            column-gap: 0;
        }
        .items{
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            column-gap: 1rem;
            padding: 10px 0;
            width: 80%;
            img{
                width: 70%;
            }
            .info{
                padding: 20px 0 0;
                // text-align: center;
                h4{
                    font-size:24px;
                    font-weight: 700;
                    color: $black;
                    padding-bottom: 10px;
                    letter-spacing: 0.25px;
                }
                p{
                    font-size: 12px;
                    line-height: 12px*1.5;
                    font-weight: 500;
                    letter-spacing: 0.2px;
                    opacity: 0.8;
                    padding-right: 0rem;
                }
    
            }
        }
        
    }
    .why_qrm{
        padding: 1rem 0;
        position: relative;
        .heading{
            padding-bottom: 10px;
            h1{
                font-size: 1.3rem;
                &::after{
                    margin-top: 6px;
                }
            }
        }  
      
        .wrap{
            display: flex;
            align-items: self-start;
            flex-direction: column;
            column-gap: 3rem;
            justify-content: space-around;
            row-gap: 1rem;
            .item{
                text-align: left;
                width: 100%;
                background-color: $white;
                padding: 1rem 0 1rem 3rem;

                
                img{
                    border-radius: 0px;
                    box-shadow: 10px 10px 20px #00000017;
                    // width: 80%;
                    // height: 100px;
                }
                h2{
                    font-size: 16px;
                    font-weight: 700;
                    letter-spacing: 0.25px;
                    line-height: 28px*1.2;
                    padding-bottom: 10px;
                    padding-top: 10px;
                    color: $sec_color;
                    text-align: left;
                }
        ul{
           li{
            list-style: none;
            text-align: start;
            display: flex;
            align-items: flex-start;
            font-size: 12px;
            line-height: 12px*1.5;
            padding-bottom: 0.5rem;
            font-weight: 600;
            ;
            i{
                padding-top: 0.2rem;
                padding-right: 1rem;
            }
         
           }
        }
        }
    }
    }
    .team{
        padding: 1rem 0;

    
        .heading{
            h1{
            font-size: 24px;
            line-height: 32px*1.2;
            letter-spacing: 0.25px;
            text-align: center;
            font-weight: 700;
            padding-bottom: 10px ;
            }
        }
        .team_subheadig{
            padding-left: 0.5rem;
            ul{
                text-align: center;
                
                li{
                    text-align: left;
                    list-style: none;
                    font-size: 12px;
                    padding-bottom: 1rem;
                    font-weight: 600;
                    line-height: 12px*1.5;
                    img{
                        width: 5%;
                    }
                }
            }
        }
        .wrap{
            display: grid;
            grid-template-columns: repeat(1,1fr);
            column-gap: 2rem;
            row-gap: 1rem;
            margin-bottom: 1.5rem;
            .card{
               
                box-shadow: 0px 0px 20px #00000015 ;
                padding: 0.5rem 1rem;
                border-radius: 5px;
                background-color: $white;
                img{
                    width: 100%;
                    height: 100px;
                }
                .card_body{
                    padding: 0.5rem 0;
                    h2{
                        font-size: 18px;
                        padding-bottom: 1rem;
                        font-weight: 800;
                        letter-spacing: 0.25px;
                    }
                    h3{
                        font-size: 14px;

                        padding-bottom: 1rem;
    
                    }
                  ul{
                    li{
                        font-size: 12px;
                        padding-bottom: 0.5rem;
                        p{
                            font-size: 12px;
                            font-weight: 600;
                        }
                        i{
                            padding-top: 3px;
                        }
                    }
                  }
                }
            }
        }
    }
}
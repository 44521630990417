@import "../../Config/Variables.scss";
footer{
    padding: 3rem 0 0;
    background-color:$sec_color;
    color :$black;
    .footer_logo{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 40px;
        border-bottom: 1px solid #aaa8a8a5;

        .logo{
            img{
                width: 50%;
            }
        }
        h3{
            font-size: 20px;
            line-height: 24px*1.3;
            color :$white;
            font-family: "Popines",sans-serif;
            display: none;
        }
        form{
            width: 30%;
            display: flex;
            // column-gap: 1rem;
            input{
                width: 80%;
                padding: 0.5rem;
            }
            button{
                background-color: $pry_color;
                border: none;
                width: 30%;
                font-size: 14px;
                font-weight: 600;
            }
        }
    }
    .footer_wrap{
        padding: 40px 0;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        .info{
            h4{
                font-size: 18px;
                font-weight: 700;
                letter-spacing: 0.25px;
                padding-bottom: 40px;
                color: $pry_color;
                position: relative;
                // display: inline;
                &::after{
                    background-color: $pry-color;
                    position: absolute;
                    // top: 0;
                    left: 0;
                    right: 0;
                    bottom: 25px;
                    width: 50px;
                    height: 3px;
                    content: "";
                    // margin-bottom: 20px;

                }
            }
            ul{
                .cont_info{
                    display: flex;
                    align-items:center; 
                    // padding-bottom: 1rem;
                    i{
                        color: $white;
                        padding-right: 1rem;
                        padding-bottom: 0.5rem;
                    }
                    li{
                        list-style: none;
                        padding-bottom: 0.5rem;
                        font-size: 16px;
                        color: $white;
                        a{
                            font-size: 16px ;
                            line-height: 14px*1.5;
                            font-weight: 500;
                            letter-spacing: 0.5px;
                            color:$white;
                            &:hover{
                                color :$pry_color;
                                text-decoration: underline;
                                cursor: pointer;
                                transition: 0.4s ease-in-out;
                            }
                        }
                    }
                }
            }
        }
    }
    .footer_bottm{
        padding: 20px 0  40px  ;
        border-top: 1px solid #aaa8a8a5;
        display: flex;
        justify-content: space-between;
        align-items: center ;
        
        p{
            color: #d9d9d9;
            font-size: 14px;
            font-style: 500;
            letter-spacing: 0.2px;
            margin: 0;
            span{
                font-size: 10px;
            }
        }
        ul{
            li{
                list-style: none;
                display: inline;
                padding: 0 1rem ;
                a{
                    font-size: 24px;
                    i{
                        color :$white;

                    }
                }
            }
        }
    }
}

@media screen and (max-width:1280px) and (min-width:992px) {
    footer{
        padding: 3rem 0 0;
        background-color:$sec_color;
        color :$black;
        .footer_logo{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 40px;
            border-bottom: 1px solid #aaa8a8a5;
    
            .logo{
                img{
                    width: 50%;
                }
            }
            h3{
                font-size: 16px;
                line-height: 24px*1.3;
                color :$white;
                font-family: "Popines",sans-serif;
            }
            form{
                width: 30%;
                display: flex;
                // column-gap: 1rem;
                input{
                    width: 80%;
                    padding: 0.5rem;
                }
                button{
                    background-color: $pry_color;
                    border: none;
                    width: 30%;
                    font-size: 14px;
                    font-weight: 600;
                }
            }
        }
        .footer_wrap{
            padding: 40px 0;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            .info{
                h4{
                    font-size: 18px;
                    font-weight: 700;
                    letter-spacing: 0.25px;
                    padding-bottom: 40px;
                    color: $pry_color;
                }
                ul{
                    .cont_info{
                        display: flex;
                        align-items: center; 
                        i{
                          
                            padding-right: 1rem;
                            padding-bottom: 0.5rem;
                        }
                    }
                    li{
                        list-style: none;
                        padding-bottom: 0.5rem;
                        a{
                            font-size: 12px ;
                            line-height: 14px*1.5;
                            font-weight: 500;
                            letter-spacing: 0.5px;
                            
                            &:hover{
                                color :$pry_color;
                                text-decoration: underline;
                                cursor: pointer;
                                transition: 0.4s ease-in-out;
                            }
                        }
                    }
                }
            }
        }
        .footer_bottm{
            padding: 20px 0  40px  ;
            border-top: 1px solid #aaa8a8a5;
            display: flex;
            justify-content: space-between;
            align-items: center ;
            
            p{
                color: $white;
                font-size: 10px;
                font-style: 500;
                letter-spacing: 0.2px;
                padding-bottom: 0.5rem;
                opacity: 0.5;
            }
            ul{
                li{
                    list-style: none;
                    display: inline;
                    padding: 0 1rem ;
                    a{
                        font-size: 24px;
                        i{
                            color :$white;
    
                        }
                    }
                }
            }
        }
    }
}
@media screen and (max-width:991px)  and (min-width:768px){ 
    footer{
        padding: 3rem 0 0;
        background-color:$sec_color;
        color :$black;
        .footer_logo{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 20px;
            border-bottom: 1px solid #aaa8a8a5;
    
            .logo{
                img{
                    width: 30%;
                }
            }
            h3{
                font-size: 16px;
                line-height: 24px*1.3;
                color :$white;
                font-family: "Popines",sans-serif;
            }
            form{
                width: 30%;
                display: flex;
                // column-gap: 1rem;
                input{
                    width: 80%;
                    padding: 0.5rem;
                }
                button{
                    background-color: $pry_color;
                    border: none;
                    width: 30%;
                    font-size: 14px;
                    font-weight: 600;
                }
            }
        }
        .footer_wrap{
            padding: 20px 0;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            .info{
                h4{
                    font-size: 18px;
                    font-weight: 700;
                    letter-spacing: 0.25px;
                    padding-bottom: 10px;
                    color: $pry_color;
                    margin-bottom: 20px;
                    &::after{
                        background-color: $pry-color;
                        position: absolute;
                        // top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        width: 40px;
                        height: 3px;
                        content: "";
                        // margin-bottom: 20px;
    
                    }
                }
                ul{
                    .cont_info{
                        display: flex;
                        align-items: flex-start; 
                        i{
                           
                            padding-right: 1rem;
                            padding-bottom: 0.5rem;
                        }
                        li{
                            list-style: none;
                            padding-bottom: 0.5rem;
                            font-size: 12px;
                           
                            a{
                                font-size: 12px ;
                                line-height: 10px*1.5;
                                font-weight: 500;
                                letter-spacing: 0.5px;
                              
                                &:hover{
                                    color :$pry_color;
                                    text-decoration: underline;
                                    cursor: pointer;
                                    transition: 0.4s ease-in-out;
                                }
                            }
                        }
                    }
                }
            }
        }
        .footer_bottm{
            padding: 20px 0  40px  ;
            border-top: 1px solid #aaa8a8a5;
            display: flex;
            justify-content: space-between;
            align-items: center ;
            
            p{
                color: $white;
                font-size: 10px;
                font-style: 500;
                letter-spacing: 0.2px;
                padding-bottom: 0.5rem;
                opacity: 0.5;
            }
            ul{
                li{
                    list-style: none;
                    display: inline;
                    padding: 0 1rem ;
                    a{
                        font-size: 24px;
                        i{
                            color :$white;
    
                        }
                    }
                }
            }
        }
    }
}
@media screen and (max-width:767px)  and (min-width:447px){
    footer{
        padding: 1.5rem 0 0;
        background-color: $sec_color;
        .footer_logo{
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            padding-bottom: 10px;
            flex-direction: column;
            border-bottom: 1px solid #ffffff50;
            row-gap: 0.5rem;
            .logo{
                img{
                    width: 30%;
                }
            }
        

        }
        .footer_wrap{
            padding: 10px 0;
            display: grid;
            grid-template-columns: 3fr 1fr ;
            align-items: flex-start;
            justify-content: space-between;
            row-gap: 1rem;
            .info{
                h4{
                    font-size: 14px;
                    font-weight: 700;
                    letter-spacing: 0.25px;
                    padding-bottom: 5px;
                    color: $pry_color;
                    margin-bottom: 15px;
                    
                    &::after{
                        background-color: $pry-color;
                        position: absolute;
                        // top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        width: 30px;
                        height: 3px;
                        content: "";
                        // margin-bottom: 20px;
    
                    }
                }
                ul{
                    .cont_info{
                        display: flex;
                        align-items: flex-start; 
                        i{
                           
                            padding-right: 0.5rem;
                            font-size: 10px;
                        }
                        li{
                            list-style: none;
                            padding-bottom: 0;
                            font-size: 10px ;
                           
                            padding-bottom: 0.5rem;

                            a{
                                font-size: 10px ;
                                line-height: 0*1.5;
                                font-weight: 500;
                                letter-spacing: 0.5px;
                       
                                &:hover{
                                    color :$pry-color;
                                    text-decoration: underline;
                                    cursor: pointer;
                                    transition: 0.4s ease-in-out;
                                }
                            }
                        }
                    }
                }
            }
        }
        .footer_bottm{
            padding: 20px 0  40px  ;
            border-top: 1px solid #ffffff50;
            display: flex;
            justify-content: space-between;
            flex-direction: column-reverse;
            align-items: flex-start ;
            
            p{
                color: $white;
                opacity: 0.5;
                font-size: 10px;
                font-style: 500;
                letter-spacing: 0.2px;
                padding-bottom: 0.5rem;
            }
            ul{
                li{
                    list-style: none;
                    display: inline;
                    padding: 0 0.5rem ;
                    text-align: left;
                    a{
                        font-size: 18px;
                        i{
                            color :$white;
    
                        }
                    }
                }
            }
        }
    }   
}
@media screen and (max-width:446px)  and (min-width:320px){
    footer{
        padding: 1.5rem 0 0;
        background-color: $sec_color;
        .footer_logo{
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            padding-bottom: 10px;
            flex-direction: column;
            border-bottom: 1px solid #ffffff50;
            row-gap: 0.5rem;
            .logo{
                img{
                    width: 30%;
                }
            }
            h3{
                font-size: 12px;
                line-height: 16px*1.3;
                color :$white;
                font-family: "Popines",sans-serif;
                text-align: left;
            }
            form{
                width: 100%;
                display: flex;
                // column-gap: 1rem;
                input{
                    width: 80%;
                    padding: 0.5rem;
                }
                button{
                    background-color: $pry_color;
                    border: none;
                    width: 30%;
                    font-size: 14px;
                    font-weight: 600;
                }
            }
        }
        .footer_wrap{
            padding: 10px 0;
            display: grid;
            grid-template-columns: 3fr 1fr ;
            align-items: flex-start;
            justify-content: space-between;
            row-gap: 1rem;
            .info{
                h4{
                    font-size: 14px;
                    font-weight: 700;
                    letter-spacing: 0.25px;
                    padding-bottom: 10px;
                    color: $pry_color;
                    margin-bottom: 1rem;
                    &::after{
                        background-color: $pry-color;
                        position: absolute;
                        // top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        width: 30px;
                        height: 3px;
                        content: "";
                        // margin-bottom: 20px;
    
                    }
                }
                ul{
                    .cont_info{
                        display: flex;
                        align-items: flex-start; 
                        i{
                        

                            padding-right: 0.5rem;
                            padding-top: 0.2rem;
                            font-size: 10px;
                        }
                        li{
                            list-style: none;
                            padding-bottom: 0.3rem;
                            font-size: 10px ;
                          

                            a{
                                font-size: 10px ;
                                line-height: 10px*1.5;
                                font-weight: 500;
                                letter-spacing: 0.5px;
                          
                               
                                &:hover{
                                    color :$pry-color;
                                    text-decoration: underline;
                                    cursor: pointer;
                                    transition: 0.4s ease-in-out;
                                }
                            }
                        }
                    }
                }
            }
        }
        .footer_bottm{
            padding: 20px 0  40px  ;
            border-top: 1px solid #ffffff50;
            display: flex;
            justify-content: space-between;
            flex-direction: column-reverse;
            align-items: flex-start ;
            
            p{
                color: $white;
                opacity: 0.5;
                font-size: 10px;
                font-style: 500;
                letter-spacing: 0.2px;
                padding-bottom: 0.5rem;
            }
            ul{
                li{
                    list-style: none;
                    display: inline;
                    padding: 0 0.5rem ;
                    text-align: left;
                    a{
                        font-size: 18px;
                        i{
                            color :$white;
    
                        }
                    }
                }
            }
        }
    }   
}

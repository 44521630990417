@import"./Config/Variables.scss";
*{
    margin: 0;
    padding: 0;
}
body{
    margin: 0;
    padding: 0;
}
html{
    scroll-behavior: smooth;
}

h1,h2,h3,h4,h5,h6{
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif;
}
.accordion-button{
  
  img{
      width: 6%;
      margin-right: 1rem;
  }
  
}

.accordion-button:not(.collapsed){
  color: $pry-color;
}
ul{
    margin: 0;
    padding: 0;
}
li{
  font-family: 'Montserrat', sans-serif;

}


a{
    text-decoration: none;
    font-family: 'Montserrat', sans-serif;

}
.no-scroll-top{
  scroll-behavior:unset ;
}
.topTOBack{
  position: fixed;
  right: 50px;
  bottom: 0;
  height: 20px;
  font-size: 1.5rem;
  z-index: 1;
  cursor: pointer;
  border:none;
  display: inline;
  i{
    background-color: $pry-color;
    padding: 0.5rem 0.8rem;
    border-radius: 5px;
    box-shadow: 0 0 20px #00000065;
  }
  
}
@media screen and (max-width:447px) and (min-width:320px){
  .topTOBack{
    position: fixed;
    right: 50px;
    left: 0;
    bottom: 0;
    height: 20px;
    z-index: 1;
    cursor: pointer;
    border:none;
    display: block;
    background-color: transparent;
    i{
      background-color: $pry-color;
      padding: 0.5rem 0.8rem;
      border-radius: 5px;
      font-size: 1rem;
      
      box-shadow: 0 0 20px #00000065;
    }
    
  }
}
.active{
  color: red;
}
.slick-slider {
  i{
    position: absolute;
    top: 50%;
    z-index: 2;
    background-color: $sec_color;
    color: $white;
    padding: 0.5rem ;
    box-shadow: 0 0 20px #00000029;
    border-radius: 3px;
  }
  .nextArrow{
    i{
      right: 10px;
    }
  }
}

@media screen and (max-width:992px)  {
  .slick-slider {
    i{
      position: absolute;
      top: 50%;
      z-index: 2;
      background-color: $sec_color;
      color: $white;
      padding: 0.5rem ;
      box-shadow: 0 0 20px #00000029;
      border-radius: 3px;
    }
    .nextArrow{
      i{
        right: -30px;
      }
    }
    .prevArow{
      i{
        left: -30px;
      }
    }
  }
}
@media screen and (max-width:580px) {
  .slick-slider {
    i{
      position: absolute;
      top: 50%;
      z-index: 2;
      background-color: $sec_color;
      color: $white;
      padding: 0.5rem ;
      box-shadow: 0 0 20px #00000029;
      border-radius: 3px;
    }
    .nextArrow{
      i{
        right: -5px;
      }
    }
    .prevArow{
      i{
        left: -5px;
      }
    }
  }
}
@media screen and (max-width:480px) {
  .slick-slider {
    i{
      position: absolute;
      top: 50%;
      z-index: 2;
      background-color: $sec_color;
      color: $white;
      padding: 0.5rem ;
      box-shadow: 0 0 20px #00000029;
      border-radius: 3px;
    }
    .nextArrow{
      i{
        right: -5px;
      }
    }
    .prevArow{
      i{
        left: -5px;
      }
    }
  }
}


@import "../../Config/Variables.scss";
@import "../../Config/Mixin.scss";


.bnr {
    background-color: $pry-color;
    padding: 5rem 0;

    .bnr_wrap {
        display: flex;
        align-items: flex-start;
        column-gap: 3rem;

        .info {
            width: 100%;

            h1 {
                font-size: 2rem;
                line-height: 2.5rem*1.3;
                font-weight: 800;
                color: $sec_color;
                padding: 10px 0 20px;
                letter-spacing: 1px;
                margin: 0;
            }
            h2{
                font-size: 1rem;
                text-transform: uppercase;
                letter-spacing: 5px;
                font-weight: 700;
                font-style: normal;
            }
            h3{
                font-size: 14px;
                font-weight: 600;
                padding: 0 0 20px;
                letter-spacing: 1px;
                margin: 0;
            }

            .pera {
                display: flex;
                align-items: flex-start;
                padding-bottom: 10px;

                img {
                    width: 3%;
                }

                p {
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 18px*1.2;
                    opacity: 0.9;
                    letter-spacing: 0.255px;
                    padding-left: 1rem;
                    margin: 0;
                }
            }

            // .btn{
            //     margin-top: 30px;
            //     a{

            //         background-color: $sec_color;
            //         padding: 0.8rem 2rem;
            //         color: $pry_color;
            //         font-size: 16px;
            //         font-weight: 600;
            //         letter-spacing: 0.25px;
            //         border-radius: 3px;
            //         box-shadow: 10px  10px  20px #00000017;
            //         margin-top: 5rem
            //     }
            // }
        }

        .hero_img {
            width: 50%;
            text-align: right;

            img {
                width: 80%;
                filter: drop-shadow(10px 10px 20px #502e3c3e);
            }
        }
    }
}

.service {
    padding: 2rem 0 3rem;
    background-color: #fdb61c20;
    .heading {
        text-align: left;
        @include heading;
    }

    .wrap {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        column-gap: 1.5rem;

        .item {
            text-align: center;
            background-color: $white;
            padding: 1rem;
            border-radius: 5px;
            box-shadow: 5px 5px 20px #00000007;
            border-top: 3px solid $pry_color;

            img {
                width: 15%;
            }

            h2 {
                font-family: 'Montserrat', sans-serif;
                font-size: 18px;
                font-weight: 600;
                padding: 10px 0;
                color: $sec_color;
                padding: 20px 0;

            }

            ul {
                li {
                    list-style: none;
                    text-align: left;
                    position: relative;
                    padding-left: 1rem;
                    font-size: 14px;
                    padding-bottom: 1rem;
                    display: flex;
                    align-items: flex-start;
                    justify-content: flex-start;
                    column-gap: 1rem;

                    img {
                        width: 10%;
                    }

                    p {
                        margin: 0;
                        font-weight: 600;
                    }
                }
            }

            transition: 0.6sease-in-out;

            &:hover {

                box-shadow: 10px 10px 30px #0000005d;
                transform: translateY(-10px);
                cursor: pointer;


            }
        }

    }

}

.mobile {
    display: none;
  
}

.solutions {
    background-color: $pry-color;
    padding: 2rem 0;

    .heading {
        text-align: center;
        padding-bottom: 40px;

        h1 {
            font-size: 36px;
            font-weight: 700;
            position: relative;

            span {
                color: $sec_color;
            }
        }

        h1:before {
            width: 28px;
            height: 5px;
            display: block;
            content: "";
            position: absolute;
            bottom: -2px;
            left: 50%;
            margin-left: -14px;
            background-color: $sec_color;
        }

        h1:after {
            width: 100px;
            height: 1px;
            display: block;
            content: "";
            position: relative;
            margin-top: 25px;
            left: 50%;
            margin-left: -50px;
            background-color: $sec_color;
        }
    }

    .wrap {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        column-gap: 1.5rem;
        row-gap: 3rem;

        .item {
            text-align: center;
            background-color: $white;
            padding: 1rem;
            border-radius: 10px;
            box-shadow: 5px 5px 20px #0000002d;
            border-top: 3px solid #000000;

            img {
                width: 50px;
                height: 50px;
                border-radius: 0;

            }

            .info {

                h2 {
                    font-size: 18px;
                    font-weight: 600;
                    padding: 10px 0 20px;
                    color: $sec_color;
                }

                ul {
                    li {
                        list-style: none;
                        text-align: left;
                        position: relative;
                        padding-left: 1rem;
                        font-size: 14px;
                        padding-bottom: 1rem;
                        color: $black;
                        display: flex;
                        align-items: flex-start;
                        justify-content: flex-start;
                        column-gap: 1rem;

                        img {
                            width: 10%;
                            height: 100%;
                        }

                        p {
                            margin: 0;
                            font-weight: 600;
                        }
                    }
                }
            }

            &:hover {
                box-shadow: 0px 0px 20px #00000046;
                animation: popup 0.3s ease-in-out;
                transform: translateY(-10px);
                cursor: pointer;

            }
        }

    }
}
.hero {
    // background-color:$pry-color;
    padding: 2rem 0 2rem;
    background-color: #fdb61c20;
    .heading {
        @include heading;

        h1 {
            &::after {
                background-color: $black;
            }

            &::before {
                background-color: $black;
            }
        }
    }

    .domain_wrap {
        display: flex;
        align-items: stretch;
        flex-direction: row;

        nav {
            width: 40%;
            background-color: $white;
            padding: 2rem;
            border-right: 1px solid $sec_color;

            ul {
                li {
                    cursor: pointer;
                    list-style: none;
                    padding: 1rem;
                    background-color: #dfdfdf4b;
                    margin: 2rem 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    a {
                        font-size: 20px;
                        line-height: 18px*1.5;
                        font-weight: 600;
                        font-family: 'Montserrat', sans-serif;
                        color: $sec_color;
                        padding-left: 1rem;
                        padding-right: 3rem;

                    }

                    img {
                        width: 10%;

                    }
                }

                .active {
                    background-color: $pry_color;
                    border-radius: 3px;

                    a {
                        color: $black;
                    }

                    i {
                        color: $black;
                    }
                }
            }
        }

        .content_box {
            padding: 2rem;
            background-color: $white;
            width: 100%;

            .health {
                padding: 2rem 0;

                .wrap {
                    display: flex;
                    justify-content: space-between;
                    column-gap: 3rem;
                    align-items: flex-start;

                    img {
                        width: 50%;
                    }

                    h1 {
                        font-size: 28px;
                        font-weight: 600;
                        line-height: 28px*1.3;
                        padding-bottom: 40px;
                        animation: biki 0.4s ease-in-out initial;
                    }

                    @keyframes biki {
                        0% {
                            transform: translateY(-10%);
                        }

                        100% {
                            transform: translateY(0);
                        }
                    }

                    ul {
                        li {
                            list-style: none;
                            text-align: start;
                            display: flex;
                            align-items: center;
                            font-size: 18px;
                            font-weight: 600;
                            line-height: 16px*1.5;
                            padding-bottom: 1rem;

                            img {
                                width:7%;
                                height: 100%;
                                margin-right: 1rem;
                                opacity: 1;
                            }
                        }

                    }
                }
            }
        }
    }

    .mobile {
        display: none;
    }
}

.explains {
    padding: 2rem 0;
    background-color: $pry-color;


    .heading {
        @include heading;
        padding: 0;
    }

    .content_box {
        width: 100%;

        .health {
            padding: 2rem 0;

            .wrap {
                h1 {
                    font-size: 24px;
                    line-height: 20px*1.5;
                    padding-bottom: 20px;
                    font-weight: 600;
                    padding-bottom: 40px;
                }

                ul {
                    display: grid;
                    grid-template-columns: repeat(3, 1fr);
                    column-gap: 2rem;

                    li {
                        border-top: 3px solid $sec_color;
                        padding: 1rem;
                        list-style: none;
                        background-color: $white;
                        border-radius: 5px;

                        .infos {
                            display: flex;
                            align-items: flex-start;
                            column-gap: 0.5rem;
                            padding-bottom: 2rem;

                            img {
                                width: 7%;

                            }

                            p {
                                margin: 0;
                                font-size: 16px;
                                font-weight: 600;
                                position: relative;

                            }
                        }

                        h1 {
                            font-size: 20px;
                            line-height: 20px*1.5;
                            padding-bottom: 20px;
                            font-weight: 600;
                            padding-bottom: 40px;
                        }
                        &:hover{
                            transform: scale(1.03);
                            cursor: pointer;
                            box-shadow: 10px 10px 20px #00000048;
                            transition:  0.4s ease-in-out;
                        }
                    }

                }
            }
        }
    }

    .backButton{
        text-align: center;

        a{
            color: $pry-color;
            background-color: $black;
            font-weight: 600;
            padding: 0.5rem 1rem;
            font-size: 18px;
            font-style: normal;
            letter-spacing: 0.25px;
            border-radius: 3px;
        }
    }

}

.solutionexplains {
    padding: 2rem 0;
    background-color: #fdb61c20;

    .heading {
        @include heading;
        padding: 0;
    }

    .content_box {
        width: 100%;

        .health {
            padding: 2rem 0;

            .wrap {
                h1 {
                    font-size: 24px;
                    line-height: 20px*1.5;
                    padding-bottom: 20px;
                    font-weight: 600;
                    padding-bottom: 40px;
                }

                ul {
                    display: grid;
                    grid-template-columns: repeat(3, 1fr);
                    column-gap: 2rem;

                    li {
                        border-top: 3px solid $pry-color;
                        padding: 1rem;
                        list-style: none;
                        background-color: $white;
                        border-radius: 5px;
                        box-shadow: 10px 10px 20px #00000027;

                        .infos {
                            display: flex;
                            align-items: flex-start;
                            column-gap: 0.5rem;
                            padding-bottom: 2rem;

                            img {
                                width: 7%;

                            }

                            p {
                                margin: 0;
                                font-size: 16px;
                                font-weight: 600;
                                position: relative;

                            }
                        }

                        ul {
                            display: block;

                            li {
                                box-shadow: none;
                                border: none;
                                .infos {
                                    display: flex;
                                    align-items: flex-start;
                                    column-gap: 0.5rem;
                                    padding-bottom: 0rem;

                                    img {
                                        width: 7%;

                                    }

                                    p {
                                        margin: 0;
                                        font-size: 16px;
                                        font-weight: 600;
                                        position: relative;

                                    }
                                }
                                &:hover{
                                    transform: scale(1);
                                }
                            }
                        }

                        h1 {
                            font-size: 20px;
                            line-height: 20px*1.5;
                            padding-bottom: 20px;
                            font-weight: 600;
                            padding-bottom: 40px;
                        }
                        &:hover{
                            transform: scale(1.03);
                            cursor: pointer;
                        }
                    }

                }
            }
        }
    }

    .backButton{
        text-align: center;
        a{
            color: $black;
            background-color: $pry-color;
            font-weight: 600;
            padding: 0.5rem 1rem;
            font-size: 18px;
            font-style: normal;
            letter-spacing: 0.25px;
            border-radius: 3px;
        }
    }
}

@media screen and (max-width:1280px) and (min-width:992px) {
    .bnr {
        padding: 3rem 0;
        .bnr_wrap {
            column-gap: 0.5rem;

            .info {
                width: 100%;

                h1 {
                    font-size: 1.5rem;
                    line-height: 1.5rem*1.3;
                    padding-bottom: 30px;
                }

                .pera {
                    padding-bottom: 10px;

                    i {
                        font-size: 16px;
                        padding-right: 1rem;
                        color: $sec_color;
                        padding-top: 0.2rem;
                    }

                    p {
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 16px*1.5;
                        opacity: 0.9;
                        letter-spacing: 0.255px;
                    }
                }

                .btn {
                    margin-top: 20px;

                    a {
                        // border: 2px solid $pry_color;
                        background-color: $sec_color;
                        padding: 0.8rem 1.5rem;
                        color: $pry_color;
                        font-size: 16px;
                        font-weight: 600;
                        letter-spacing: 0.25px;
                        border-radius: 3px;
                        box-shadow: 10px 10px 20px #00000017;
                        margin-top: 5rem
                    }
                }
            }

            .hero_img {
                width: 70%;
                text-align: right;

                img {
                    width: 80%;
                    border-radius: 10px;
                    filter: drop-shadow(10px 10px 20px #502e3c3e);
                }
            }
        }
    }

    .service {
        position: relative;
        padding: 2rem 0 4rem;
        background-color: #fdb61c20;

        .heading {
            text-align: left;
            @include heading;

            h1 {
                font-size: 28px;
            }
        }

        .wrap {
            display: grid;
            grid-template-columns: repeat(2,1fr);
            row-gap: 2rem;
            .item {
                padding: 1rem 0.7rem;

                h2 {
                    font-size: 16px;
                    font-weight: 700;

                }
                img{
                    width: 10%;
                }
                ul{
                    li{
                        img{
                            width: 5%;
                        }

                        p {
                            text-align: left;
                            font-size: 14px;
                            line-height: 14px*1.5;
                            font-weight: 600;
                        }
                    }
                }


            }

        }

    }

    .mobile {
        display: none;
    }

    .solutions {
        background-color: $pry-color;
        position: relative;
        padding: 2rem 0;

        .heading {
            text-align: center;
            padding-bottom: 30px;

            h1 {
                font-size: 28px;
                font-weight: 700;
                position: relative;
            }
        }

        .wrap {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            column-gap: 1.5rem;
            row-gap: 1.5rem;

            .item {
                text-align: center;
                background-color: $white;
                padding: 1rem;
                border-radius: 10px;
                // box-shadow: 5px 5px 20px #0000002d;
                border: 1px solid #00000017;

                .info {
                    text-align: left;

                    h2 {
                        font-size: 18px;
                        font-weight: 700;
                        padding: 10px 0;
                        color: $sec_color;
                        text-align: center;
                    }
                    ul{
                        li{
                           p{
                            font-size: 14px; 
                            font-weight: 600;
                            }
                            img{
                                width: 5%;
                            }
                        }
                    }
                }

                img {
                    width: 50px;
                    height: 50px;
                    border-radius: 0;

                }
            }
        }
    }



    .why_qrm {
        padding: 2rem 0;
        position: relative;

        .heading {
            @include heading;
            padding-bottom: 20px;

            h1 {
                font-size: 28px;
            }

            h1::before {
                background-color: $black;
            }

            h1::after {
                background-color: $black;
            }
        }

        .wrap {
            display: flex;
            grid-template-columns: repeat(3, 1fr);
            align-items: self-start;
            column-gap: 0.5rem;
            justify-content: space-around;

            .item {
                text-align: inherit;
                width: 70%;

                img {
                    border-radius: 10px;
                    box-shadow: 10px 10px 20px #00000017;
                    width: 80%;
                }

                h2 {
                    font-size: 16px;
                    font-weight: 700;
                    letter-spacing: 0.25px;
                    line-height: 28px*1.2;
                    padding-bottom: 15px;
                    padding-top: 20px;
                    color: $sec_color;
                    text-align: left;
                }

                ul {
                    li {
                        list-style: none;
                        text-align: start;
                        display: flex;
                        align-items: flex-start;
                        font-size: 14px;
                        line-height: 14px*1.5;
                        padding-bottom: 0.5rem;

                        i {
                            padding-top: 0.2rem;
                            padding-right: 1rem;
                        }
                    }
                }
            }
        }
    }

    .hero {
        background-color: #fdb61c20;
        padding: 2rem 0;

        .heading {
            @include heading;
            padding-bottom: 20px;

            h1 {
                font-size: 28px;
            }
        }

        .domain_wrap {
            nav {
                width: 50%;
                background-color: $white;
                padding: 2rem;
                // border-left: 1px solid $sec_color;

                ul {

                    li {
                        padding: 0.5rem;
                        margin: 1rem 0;

                        a {
                            font-size: 14px;
                            line-height: 18px*1.5;
                        }

                        img {
                            width: 10%;
                        }
                    }

                    .active {
                        background-color: $pry_color;
                        border-radius: 3px;

                        a {
                            color: $black;
                        }

                        i {
                            color: $black;
                        }
                    }
                }
            }

            .content_box {
                padding: 2rem;
                background-color: $white;
                width: 100%;

                .health {
                    padding: 2rem 0;

                    .wrap {
                        display: flex;
                        justify-content: space-between;
                        column-gap: 3rem;
                        align-items: flex-start;

                        img {
                            width: 50%;
                        }

                        h1 {
                            font-size: 24px;
                            font-weight: 600;
                            line-height: 28px*1.3;
                            padding-bottom: 20px;
                            animation: biki 0.4s ease-in-out initial;
                        }

                        @keyframes biki {
                            0% {
                                transform: translateY(-10%);
                            }

                            100% {
                                transform: translateY(0);
                            }
                        }

                        ul {
                            li {
                                list-style: none;
                                text-align: start;
                                display: flex;
                                align-items: flex-start;
                                font-size: 14px;
                                font-weight: 600;
                                line-height: 14px*1.5;
                                padding-bottom: 0.5rem;

                                img{
                                    padding-top:0.5rem;
                           
                                }
                            }

                        }
                    }
                }
            }
        }

        .mobile {
            display: none;
        }
    }
    .explains {
        padding: 2rem 0;
        background-color: $pry-color;
    
    
        .heading {
            @include heading;
            padding: 0;
        }
    
        .content_box {
            width: 100%;
    
            .health {
                padding: 2rem 0;
    
                .wrap {
             
    
                    ul {
                        display: grid;
                        grid-template-columns: repeat(3, 1fr);
                        column-gap: 2rem;
    
                        li {
                            border-top: 3px solid $sec_color;
                            padding: 1rem;
                            list-style: none;
                            background-color: $white;
                            border-radius: 5px;
    
                            .infos {
                                display: flex;
                                align-items: flex-start;
                                column-gap: 0.5rem;
                                padding-bottom: 2rem;
    
                                img {
                                    width: 7%;
    
                                }
    
                                p {
                                    margin: 0;
                                    font-size: 14px;
                                    font-weight: 600;
                                    position: relative;
    
                                }
                            }
    
                            h1 {
                                font-size: 20px;
                                line-height: 20px*1.5;
                                padding-bottom: 20px;
                                font-weight: 600;
                                padding-bottom: 40px;
                            }
                            &:hover{
                                transform: scale(1.03);
                                cursor: pointer;
                                box-shadow: 10px 10px 20px #00000048;
                                transition:  0.4s ease-in-out;
                            }
                        }
    
                    }
                }
            }
        }
    
    }
    
    .solutionexplains {
        padding: 2rem 0;
        background-color: #fdb61c20;
    
        .heading {
            @include heading;
            padding: 0;
        }
    
        .content_box {
            width: 100%;
    
            .health {
                padding: 2rem 0;
    
                .wrap {
                    h1 {
                        font-size: 24px;
                        line-height: 20px*1.5;
                        padding-bottom: 20px;
                        font-weight: 600;
                        padding-bottom: 40px;
                    }
    
                    ul {
                        display: grid;
                        grid-template-columns: repeat(3, 1fr);
                        column-gap: 2rem;
    
                        li {
                            border-top: 3px solid $pry-color;
                            padding: 0.5rem;
                            list-style: none;
                            background-color: $white;
                            border-radius: 5px;
                            box-shadow: 10px 10px 20px #00000027;
    
                            .infos {
                                display: flex;
                                align-items: flex-start;
                                column-gap: 0.5rem;
                                padding-bottom: 2rem;
    
                                img {
                                    width: 7%;
    
                                }
    
                               
                            }
    
                            ul {
                                display: block;
    
                                li {
                                    box-shadow: none;
                                    border: none;
                                    .infos {
                                        display: flex;
                                        align-items: flex-start;
                                        column-gap: 0.5rem;
                                        padding-bottom: 0rem;
    
                                        img {
                                            width: 7%;
    
                                        }
    
                                        p {
                                            margin: 0;
                                            font-size: 14px;
                                            font-weight: 600;
                                            position: relative;
    
                                        }
                                    }
                                    &:hover{
                                        transform: scale(1);
                                    }
                                }
                            }
    
                            h1 {
                                font-size: 20px;
                                line-height: 20px*1.5;
                                padding-bottom: 20px;
                                font-weight: 600;
                                padding-bottom: 40px;
                            }
                            &:hover{
                                transform: scale(1.03);
                                cursor: pointer;
                            }
                        }
    
                    }
                }
            }
        }
    }
}

@media screen and (max-width:991px) and (min-width:768px) {
    .bnr {
        padding: 2rem 0;
        .bnr_wrap {
            display: flex;
            column-gap: 1rem;
            flex-direction: column-reverse;
            .info {
                width: 100%;
                h1 {
                    font-size: 1.7rem;
                    padding-bottom: 30px;
                    padding-top: 30px;
                    line-height: 1.3rem*1.3;
                }

                h2{
                    padding-top: 1.3rem;
                    font-size: 14px;
                }
                .pera{
                    padding-bottom: 0;
                    i {
                        font-size: 12px;
                        padding-right: 0.5rem;
                        padding-top: 0.1rem;
                    }

                    p {
                        font-size: 12px;
                        line-height: 12px*1.5;
                        letter-spacing: 0.255px;
                        margin-bottom: 0.5rem;
                    }
                }

                .btn {
                    margin-top: 10px;
                    a {
                        padding: 0.5rem 1.5rem;
                        font-size: 12px;
                        letter-spacing: 0.25px;
                    }
                }
            }

            .hero_img {
                width: 100%;
                text-align: left;

                img {
                    width: 70%;
                    border-radius: 10px;
                    filter: drop-shadow(10px 10px 20px #502e3c3e);
                }
            }
        }
    }

    .service {
        position: relative;
        padding: 1rem 0 2rem;

        .mobile {
            .heading {
                padding-bottom: 20px;

                h1 {
                    font-size: 1.3rem;

                    &::after {
                        margin-top: 7px;
                    }
                }

                ul {
                    padding: 1rem 0 0.5rem;

                    li {
                        list-style: circle;
                        font-size: 12px;
                        display: inline;
                        font-weight: 600;
                        padding-right: 0.5rem;

                        i {
                            font-size: 8px;
                            
                            padding-right: 1px;
                        }
                    }
                }

            }

            .wrap {
                padding: 0 0.5rem;

                .item {
                    text-align: center;
                    background-color: $white;
                    padding: 1rem 0.5REM;
                    border-radius: 10px;
                    box-shadow: 5px 5px 20px #00000007;
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    column-gap: 0.5rem;
                    height: 80vh;
                    h2 {
                        font-size: 12px;
                        font-weight: 700;
                        padding: 20px 0;
                        color: $sec_color;
                    }

                    p {
                        text-align: start;
                        font-size: 12px;
                        line-height: 12px*1.5;
                        letter-spacing: 0.25px;
                    }

                    img {
                        width: 30px;
                        height: 30px;
                        border-radius: 0;
                        

                    }
                    ul{
                        li{
                            column-gap: 0.2rem;
                            P{
                                font-weight: 600;
                            }
                            img{
                                width: 10%;
                                height: 10%;
                                
                            }
                        }
                    }

                    &:hover {
                        background-color: $white;
                        box-shadow: none;
                        transform: translateY(0);
                        cursor: pointer;

                        h2 {
                            color: $black;
                        }

                        p {
                            color: $black;
                        }
                    }

                }
            }
        }

    }

    .solutions {
        position: relative;
        padding: 2rem 0;
        background-color: $pry-color;

        .heading {
            padding-bottom: 10px;

            h1 {
                font-size: 1.5rem;

                &::after {
                    margin-top: 8px;
                }
            }

            ul {
                padding: 1rem 0 0.5rem;

                li {
                    list-style: circle;
                    font-size: 12px;
                    display: inline;
                    font-weight: 600;
                    padding-right: 0.5rem;

                    i {
                        font-size: 8px;
                        
                        padding-right: 1px;
                    }
                }
            }
        }

        .box {
            padding: 0 0.5rem;

            .item {
                text-align: center;
                background-color: $white;
                padding:1rem 0.5rem;
                border-radius: 10px;
                box-shadow: 5px 5px 20px #00000007;
                display: flex;
                align-items: center;
                flex-direction: column;
                column-gap: 0.5rem;
                height: 80vh;

                .info {
                    text-align: center;

                    h2 {
                        font-size: 16px;
                        font-weight: 600;
                        padding: 20px 0;
                        color: $sec_color;
                    }

                    p {
                        text-align: start;
                        font-size: 12px;
                        line-height: 12px*1.5;
                        letter-spacing: 0.25px;
                        // display: none;
                    }
                    ul{
                        li{
                            list-style: none;
                            display: flex;
                            align-items: flex-start;
                            column-gap: 0.2rem;
                            img{
                                width: 10%;
                                height: 10%;
                            }
                            p{
                                font-weight: 600;
                            }
                        }
                    }
                }

                img {
                    width: 40px;
                    height: 40px;
                    border-radius: 0;
                    

                }

            }


        }

    }

    .desktop {
        display: none;
    }

    .mobile {
        display: block;

    }

    .why_qrm {
        padding: 2rem 0;
        position: relative;

        //background-color: $pry-color;
        .heading {
            padding-bottom: 20px;

            h1 {
                font-size: 1.5rem;

                &::after {
                    margin-top: 8px;
                }
            }
        }

        .wrap {
            display: grid;
            grid-template-columns: 1fr 1fr;
            align-items: self-start;
            flex-direction: column;
            column-gap: 1rem;
            justify-content: space-around;
            row-gap: 1rem;

            .item {
                text-align: center;
                width: 100%;
                background-color: $white;
                padding: 0.5rem;


                img {
                    border-radius: 0px;
                    box-shadow: 10px 10px 20px #00000017;
                    width: 100%;
                    // height: 100px;
                }

                h2 {
                    font-size: 12px;
                    font-weight: 700;
                    letter-spacing: 0.25px;
                    line-height: 28px*1.2;
                    padding-bottom: 10px;
                    padding-top: 10px;
                    color: $sec_color;
                    text-align: left;
                }

                ul {
                    li {
                        list-style: none;
                        text-align: start;
                        display: flex;
                        align-items: flex-start;
                        font-size: 10px;
                        line-height: 10px*1.5;
                        padding-bottom: 0.2rem;

                        i {
                            padding-top: 0.2rem;
                            padding-right: 0.5rem;
                        }
                    }
                }
            }
        }
    }

    .hero {

        background-color: #fdb61c20;
        padding: 3rem 0 2rem;

        .heading {
            padding-bottom: 15px;

            h1 {
                font-size: 1.5rem;

                &::after {
                    margin-top: 8px;
                }
            }
        }

        .mobile {
            display: block;

            button {
                font-size: 14px;
                font-weight: 600;
                background-color: $white;
                img{
                    width: 4%;
                }
                &::after {
                    width: 20px;
                    height: 20px;
                    background-size: 15px;

                }
            }

            ul {
                li {
                    list-style: none;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 14px*1.5;

                    img {
                        padding-right: 0.5rem;
                        width: 4%;
                    }
                }
            }
        }

        .desktop {
            display: none;
        }
    }
    .explains {
        padding: 1rem 0 1rem;


        .heading {
            padding-bottom: 0px;

            h1 {
                font-size: 1.3rem;

                &::after {
                    margin-top: 8px;
                }
            }
        }

        .content_box {
            width: 100%;

            .health {
                padding: 1rem 0;

                .wrap {

                    ul {
                        display: grid;
                        grid-template-columns: repeat(3, 1fr);
                        column-gap: 1rem;

                        li {
                            .infos {
                                padding-bottom: 0.5rem;
                                
                                img {
                                    width: 5%;
                                }
                                
                                p {
                                    font-weight: 600;
                                    font-size: 12px;
                                }
                            }

                        }

                    }
                }
            }
        }

        .backButton{
            a{
                font-size: 14px;
            }
        }

    }

    .solutionexplains {
        padding: 1rem 0 1rem;

        .heading {
            padding-bottom: 0px;

            h1 {
                font-size: 1.3rem;

                &::after {
                    margin-top: 8px;
                }
            }
        }

        .content_box {
            width: 100%;

            .health {
                padding: 1rem 0;

                .wrap {
                    ul {
                        display: grid;
                        grid-template-columns: repeat(3, 1fr);
                        row-gap: 1rem;
                        column-gap: 1rem;

                        li {
                            ul {
                                li {
                                    padding: 0;
                                    font-size: 12px;

                                    .infos {
                                        padding-bottom: 0.5rem;

                                        img {
                                            width: 5%;
                                        }

                                        p {
                                            font-size: 12px;
                                            font-weight: 600;
                                        }
                                    }

                                    ul {
                                        padding-left: 1rem;
                                    }
                                }
                            }

                            h1 {
                                font-size: 16px;
                                line-height: 16px*1.5;
                                padding-bottom: 20px;
                            }

                            p {
                                font-size: 12px;
                                
                            }
                        }

                    }
                }
            }
        }

        .backButton{
            a{
                font-size: 14px;
            }
        }
    }
}

@media screen and (max-width:767px) and (min-width:448px) {
    .bnr {
        padding: 2rem 0;

        .bnr_wrap {
            display: flex;
            column-gap: 1rem;
            flex-direction: column-reverse;

            .info {
                width: 100%;

                h1 {
                    font-size: 1.3rem;
                    padding-bottom: 20px;
                    padding-top: 20px;
                    line-height: 1.3rem*1.3;
                }
                h2{
                    padding-top: 10px;
                }

                .pera {
                    padding-bottom: 0;

                    i {
                        font-size: 12px;
                        padding-right: 0.5rem;
                        padding-top: 0.1rem;
                    }

                    p {
                        font-size: 12px;
                        line-height: 12px*1.5;
                        letter-spacing: 0.255px;
                        margin-bottom: 0.5rem;
                    }
                }

                .btn {
                    margin-top: 10px;

                    // margin-bottom: 2rem1
                    a {
                        padding: 0.5rem 1.5rem;
                        font-size: 12px;
                        letter-spacing: 0.25px;
                    }
                }
            }

            .hero_img {
                width: 100%;
                text-align: left;

                img {
                    width: 80%;
                    border-radius: 10px;
                    margin-bottom: 1rem;
                    // height: 200px;
                    filter: drop-shadow(10px 10px 20px #502e3c3e);
                }
            }
        }
    }

    .service {
        position: relative;
        padding: 1rem 0 2rem;

        .heading {
            padding-bottom: 20px;

            h1 {
                font-size: 1.3rem;

                &::after {
                    margin-top: 7px;
                }
            }

            ul {
                padding: 1rem 0 0.5rem;

                li {
                    list-style: circle;
                    font-size: 12px;
                    font-weight: 600;
                    display: inline;
                    padding-right: 0.5rem;
                   
                    i {
                        font-size: 6px;
                    }

                }
            }

        }

        .wrap {
            padding: 0 0.5rem;

            img {
                width: 30px;
                height: 30px;
                border-radius: 0;
                

            }

            .item {
                text-align: center;
                background-color: $white;
                padding:1rem 0.5rem;
                border-radius: 10px;
                box-shadow: 5px 5px 20px #00000007;
                display: flex;
                align-items: center;
                flex-direction: column;
                column-gap: 0.5rem;
                height:300px;
                
                &:hover {
                    transform: translateY(0);
                }

                h2 {
                    font-size: 14px;
                    font-weight: 700;
                    padding: 20px 0;
                    color: $sec_color;
                    
                }

                ul {
                    li {
                        list-style: none;
                        text-align: left;
                        position: relative;
                        padding-left: 0.5rem;
                        font-size: 12px;
                        line-height: 12px*1.3;
                        padding-bottom: 0.5rem;
                        column-gap: 0.5rem;
                        p{
                            font-weight: 600;
                        }
                        img {
                            width: 7%;
                            height: 100%;
                        }
                    }
                }


                &:hover {
                    background-color: $white;
                    box-shadow: none;
                    transform: translateY(0);
                    cursor: pointer;

                    h2 {
                        color: $black;
                    }

                    p {
                        color: $black;
                    }
                }

            }
        }


    }

    .solutions {
        position: relative;
        padding: 1.5rem 0 3rem;

        .heading {
            padding-bottom: 10px;

            h1 {
                font-size: 1.3rem;

                &::after {
                    margin-top: 6px;
                }
            }

            ul {
                padding: 0;

                li {
                    list-style-type: disc;
                    font-size: 10px;
                    font-weight: 600;
                    display: inline;
                    padding-right: 0.5rem;

                    i {
                        font-size: 0.5rem;
                    }
                }
            }
        }

        .box {
            padding: 0 0.5rem;

            .item {
                text-align: center;
                background-color: $white;
                padding: 1rem 0.5rem;
                border-radius: 10px;
                box-shadow: 5px 5px 20px #00000007;
                display: flex;
                align-items: center;
                flex-direction: column;
                column-gap: 0.5rem;
                height: 300px;
                .info {
                    text-align: center;

                    h2 {
                        font-size: 14px;
                        font-weight: 700;
                        padding: 20px 0;
                        color: $sec_color;
                    }

                    ul {
                        li {
                            list-style: none;
                            text-align: left;
                            position: relative;
                            padding-left: 0.5rem;
                            font-size: 12px;
                            line-height: 12px*1.3;
                            letter-spacing: 0.25px;
                            padding-bottom: 0.5rem;
                            display: flex;
                            align-items: flex-start;
                            column-gap: 0.5rem;
                            margin: 0;
                            p{
                                margin-bottom: 0.2rem ;
                                font-weight: 600;
                            }

                            img {
                                width: 7%;
                                height: 100%;
                            }
                        }
                    }
                }

                img {
                    width: 40px;
                    height: 40px;
                    border-radius: 0;
                    

                }

            }


        }

    }

    .desktop {
        display: none;
    }

    .mobile {
        display: block;

    }

    .why_qrm {
        padding: 1.5rem 0 2rem;
        position: relative;

        .heading {
            padding-bottom: 10px;

            h1 {
                font-size: 1.3rem;

                &::after {
                    margin-top: 8px;
                }
            }
        }

        .wrap {
            display: flex;
            align-items: self-start;
            flex-direction: column;
            column-gap: 3rem;
            justify-content: space-around;
            row-gap: 1rem;

            .item {
                text-align: start;
                width: 100%;
                background-color: $white;
                padding: 1rem;

                img {
                    border-radius: 0px;
                    box-shadow: 10px 10px 20px #00000017;
                    width: 80%;
                    // height: 100px;
                }

                h2 {
                    font-size: 16px;
                    font-weight: 700;
                    letter-spacing: 0.25px;
                    line-height: 28px*1.2;
                    padding-bottom: 10px;
                    padding-top: 10px;
                    color: $sec_color;
                    text-align: left;
                }

                ul {
                    li {
                        list-style: none;
                        text-align: start;
                        display: flex;
                        align-items: flex-start;
                        font-size: 12px;
                        line-height: 12px*1.5;
                        padding-bottom: 0.5rem;

                        i {
                            padding-top: 0.2rem;
                            padding-right: 1rem;
                        }
                    }
                }
            }
        }
    }

    .hero {
        padding: 1rem 0 2rem;

        .heading {
            padding-bottom: 10px;

            h1 {
                font-size: 1.3rem;

                &::after {
                    margin-top: 8px;
                }
            }
        }

        .mobile {
            display: block;

            button {
                font-size: 14px;
                font-weight: 600;
                background-color: $white;

                &::after {
                    width: 20px;
                    height: 20px;
                    background-size: 15px;

                }
            }

            ul {
                li {
                    list-style: none;
                    font-size: 12px;
                    font-weight: 600;
                    line-height: 12px*1.5;
                    padding-bottom: 0.5rem;
                    img {
                        width: 3%;
                        margin-right: 0.5rem;
                    }
                }
               
            }
        }

        .desktop {
            display: none;

        }
    }

    .explains {
        padding: 1rem 0 1rem;


        .heading {
            padding-bottom: 0px;

            h1 {
                font-size: 1.3rem;

                &::after {
                    margin-top: 8px;
                }
            }
        }

        .content_box {
            width: 100%;

            .health {
                padding: 1rem 0;

                .wrap {

                    ul {
                        display: grid;
                        grid-template-columns: repeat(1, 1fr);
                        row-gap: 1rem;

                        li {
                            .infos {
                                padding-bottom: 0.5rem;
                                
                                img {
                                    width: 5%;
                                }
                                
                                p {
                                    font-weight: 600;
                                    font-size: 12px;
                                }
                            }

                        }

                    }
                }
            }
        }

        .backButton{
            a{
                font-size: 14px;
            }
        }

    }

    .solutionexplains {
        padding: 1rem 0 1rem;

        .heading {
            padding-bottom: 0px;

            h1 {
                font-size: 1.3rem;

                &::after {
                    margin-top: 8px;
                }
            }
        }

        .content_box {
            width: 100%;

            .health {
                padding: 1rem 0;

                .wrap {
                    ul {
                        display: grid;
                        grid-template-columns: repeat(1, 1fr);
                        row-gap: 1rem;

                        li {
                            ul {
                                li {
                                    padding: 0;
                                    font-size: 12px;

                                    .infos {
                                        padding-bottom: 0.5rem;

                                        img {
                                            width: 5%;
                                        }

                                        p {
                                            font-size: 12px;
                                            font-weight: 600;
                                        }
                                    }

                                    ul {
                                        padding-left: 1rem;
                                    }
                                }
                            }

                            h1 {
                                font-size: 16px;
                                line-height: 16px*1.5;
                                padding-bottom: 20px;
                            }

                            p {
                                font-size: 12px;
                                
                            }
                        }

                    }
                }
            }
        }

        .backButton{
            a{
                font-size: 14px;
            }
        }
    }
}

@media screen and (max-width:447px) and (min-width:374px) {
    .bnr {
        padding: 2rem 0;
        .bnr_wrap {
            display: flex;
            column-gap: 1rem;
            flex-direction: column-reverse;

            .info {
                width: 100%;

                h1 {
                    font-size: 1.3rem;
                    padding-bottom: 20px;
                    padding-top: 10px;
                    line-height: 1.3rem*1.3;
                }
                h2{
                    font-size: 12px;
                    margin: 0;
                    padding-top: 20px;
                    letter-spacing: 3px;
                }

                .pera {
                    padding-bottom: 0;

                   img{
                    padding-top: 0.3rem;
                    width: 0.8rem;
                   }

                    p {
                        font-size: 12px;
                        line-height: 12px*1.5;
                        letter-spacing: 0.255px;
                        margin-bottom: 0.5rem;
                    }
                }

                .btn {
                    margin-top: 10px;

                    // margin-bottom: 2rem1
                    a {
                        padding: 0.5rem 1.5rem;
                        font-size: 12px;
                        letter-spacing: 0.25px;
                    }
                }
            }

            .hero_img {
                width: 100%;
                text-align: left;

                img {
                    width: 90%;
                    filter: drop-shadow(10px 10px 15px #502e3c25);
                    border-radius: 10px;
                }
            }
        }
    }

    .service {
        position: relative;
        padding: 2rem 0 2rem;

        .heading {
            padding-bottom: 20px;

            h1 {
                font-size: 1.3rem;

                &::after {
                    margin-top: 10px;
                }
            }

            ul {
                padding: 1rem 0 0.5rem;

                li {
                    list-style: circle;
                    font-size: 12px;
                    font-weight: 600;
                    display: inline;
                    padding-right: 0.5rem;

                    i {
                        font-size: 6px;
                    }

                }
            }

        }

        .wrap {
            padding: 0 1rem;

            img {
                width: 40px;
                height: 40px;
                border-radius: 0;
            }
            
            .item {
                text-align: center;
                background-color: $white;
                padding:1rem 0.5rem;
                border-radius: 10px;
                box-shadow: 5px 5px 20px #00000007;
                display: flex;
                align-items: center;
                flex-direction: column;
                column-gap: 0.5rem;
                height: 300px;
                &:hover {
                    transform: translateY(0);
                }

                h2 {
                    font-size: 16px;
                    font-weight: 600;
                    padding: 10px 0 20px;
                    color: $sec_color;
                }

                ul {
                    li {
                        list-style: none;
                        text-align: left;
                        position: relative;
                        padding-left: 1rem;
                        font-size: 12px;
                        padding-bottom: 0.5rem;
                        p{
                            font-weight: 600;
                        }
                        img {
                            width: 5%;
                            height: 100%;
                            padding-top: 0.2rem;
                        }
                    }
                }


                &:hover {
                    background-color: $white;
                    box-shadow: none;
                    transform: translateY(0);
                    cursor: pointer;

                    h2 {
                        color: $black;
                    }

                    p {
                        color: $black;
                    }
                }

            }
        }


    }

    .solutions {
        position: relative;
        padding: 2rem 0;

        .heading {
            padding-bottom: 20px;

            h1 {
                font-size: 1.3rem;

                &::after {
                    margin-top: 10px;
                }
            }

            ul {
                padding: 1rem 0 0.5rem;

                li {
                    list-style: circle;
                    font-size: 12px;
                    font-weight: 600;
                    display: inline;
                    padding-right: 0.5rem;

                    i {
                        font-size: 6px;
                    }

                }
            }

        }

        .box {
            padding: 0 1rem;

            .item {
                text-align: center;
                background-color: $white;
                padding: 0.5rem;
                border-radius: 10px;
                box-shadow: 5px 5px 20px #00000007;
                display: flex;
                align-items: center;
                flex-direction: column;
                column-gap: 0.5rem;
                height: 300px;
                .info {
                    text-align: center;
                    h2 {
                        font-size: 16px;
                        font-weight: 700;
                        padding: 10px 0 20px;
                        color: $sec_color;
                    }

                    ul {
                        li {
                            list-style: none;
                            text-align: left;
                            position: relative;
                            padding-left: 1rem;
                            font-size: 12px;
                            display: flex;
                            align-items: flex-start;
                            column-gap: 0.5rem;
                            p{
                                font-weight: 600;
                            }
                            img {
                                width: 5%;
                                height: 100%;
                                padding-top: 0.2rem;
                            }
                        }
                    }
                }

                img {
                    width: 40px;
                    height: 40px;
                    border-radius: 0;
                    

                }

            }


        }

    }

    .desktop {
        display: none;
    }

    .mobile {
        display: block;

    }

    .hero {
        padding: 2rem 0 ;

        .heading {
            padding-bottom: 20px;

            h1 {
                font-size: 1.3rem;

                &::after {
                    margin-top: 10px;
                }
            }

            ul {
                padding: 1rem 0 0.5rem;

                li {
                    list-style: circle;
                    font-size: 12px;
                    display: inline;
                    padding-right: 0.5rem;
                   
                    i {
                        font-size: 6px;
                    }

                }
            }

        }

        .mobile {
            display: block;

            button {
                font-size: 14px;
                font-weight: 600;
                background-color: $white;
                &:focus{
                    border-color: transparent;
                    outline: none;
                    border: none;
                }

                &::after {
                    width: 20px;
                    height: 20px;
                    background-size: 15px;

                }
               
            }
            .accordion-button{
                img{
                    width: 5%;
                }
            }

            ul {
                li {
                    list-style: none;
                    font-size: 12px;
                    font-weight: 600;
                    line-height: 12px*1.5;
                
                    img {
                        width: 5%;
                        margin-right: 0.5rem;
                    }
                }
            }
        }

        .desktop {
            display: none;

        }
    }

    .explains {
        padding: 2rem 0;


        .heading {
            padding-bottom: 0px;

            h1 {
                font-size: 1.3rem;

                &::after {
                    margin-top: 8px;
                }
            }
        }

        .content_box {
            width: 100%;

            .health {
                padding: 1rem 0;

                .wrap {

                    ul {
                        display: grid;
                        grid-template-columns: repeat(1, 1fr);
                        row-gap: 1rem;

                        li {
                            .infos {
                                padding-bottom: 0.5rem;

                                img {
                                    width: 5%;
                                }

                                p {
                                    font-size: 12px;
                                    font-weight: 600;
                                }
                            }

                        }

                    }
                }
            }
        }
        .backButton{
            a{
                font-size: 12px;
            }
        }

    }


    .solutionexplains {
        padding: 2rem 0 ;

        .heading {
            padding-bottom: 0px;

            h1 {
                font-size: 1.3rem;

                &::after {
                    margin-top: 8px;
                }
            }
        }

        .content_box {
            width: 100%;

            .health {
                padding: 1rem 0;

                .wrap {
                    ul {
                        display: grid;
                        grid-template-columns: repeat(1, 1fr);
                        row-gap: 1rem;

                        li {}

                    }

                    ul {
                        display: grid;
                        grid-template-columns: repeat(1, 1fr);
                        row-gap: 1rem;

                        li {
                            ul {
                                li {
                                    padding: 0;
                                    font-size: 12px;

                                    .infos {
                                        padding-bottom: 0.5rem;

                                        img {
                                            width: 5%;
                                        }

                                        p {
                                            font-size: 12px;
                                            font-weight: 600;
                                        }
                                    }

                                    ul {
                                        padding-left: 1rem;
                                    }
                                }
                            }

                            h1 {
                                font-size: 16px;
                                line-height: 16px*1.5;
                                padding-bottom: 20px;
                            }

                            p {
                                font-size: 12px;
                            }
                        }

                    }
                }
            }
        }
        .backButton{
            a{
                font-size: 12px;
            }
        }
    }
}

@media screen and (max-width:375px) and (min-width:320px) {
    .bnr {
        padding: 2rem 0;

        .bnr_wrap {
            display: flex;
            column-gap: 1rem;
            flex-direction: column-reverse;

            .info {
                width: 100%;

                h1 {
                    font-size: 1.3rem;
                    padding-bottom: 20px;
                    padding-top: 10px;
                    line-height: 1.3rem*1.3;
                }
                h2{
                    font-size: 12px;
                    margin: 0;
                    padding-top: 20px;
                    letter-spacing: 3px;
                }

                .pera {
                    padding-bottom: 0;

                    img {
                        width: 5%;
                        height: 100%;
                        padding-right: 0.1rem;
                        padding-top: 0.2rem;
                    }

                    p {
                        font-size: 12px;
                        line-height: 12px*1.5;
                        letter-spacing: 0.255px;
                        margin-bottom: 0.5rem;
                    }
                }

                .btn {
                    margin-top: 10px;

                    // margin-bottom: 2rem1
                    a {
                        padding: 0.5rem 1.5rem;
                        font-size: 12px;
                        letter-spacing: 0.25px;
                    }
                }
            }

            .hero_img {
                width: 100%;
                text-align: left;

                img {
                    width: 90%;
                    filter: drop-shadow(10px 10px 20px #502e3c3e);
                    border-radius: 10px;
                }
            }
        }
    }

    .service {
        position: relative;
        padding: 1rem 0 2rem;

        .heading {
            padding-bottom: 20px;

            h1 {
                font-size: 1.3rem;

                &::after {
                    margin-top: 7px;
                }
            }

            ul {
                padding: 1rem 0 0.5rem;

                li {
                    list-style: circle;
                    font-size: 12px;
                    display: inline;
                    font-weight: 600;
                    padding-right: 0.5rem;

                    i {
                        font-size: 6px;
                    }

                }
            }

        }

        .wrap {
            padding: 0 1rem;

            img {
                width: 40px;
                height: 40px;
                border-radius: 0;
                

            }

            .item {
                text-align: center;
                background-color: $white;
                padding: 1rem 0.5rem;
                border-radius: 10px;
                box-shadow: 5px 5px 20px #00000007;
                display: flex;
                align-items: center;
                flex-direction: column;
                column-gap: 0.5rem;
                height: 300px;
                &:hover {
                    transform: translateY(0);
                }

                h2 {
                    font-size: 16px;
                    font-weight: 600;
                    padding: 10px 0 20px;
                    color: $sec_color;
                }

                ul {
                    li {
                        list-style: none;
                        text-align: left;
                        position: relative;
                        padding-left: 1rem;
                        font-size: 12px;
                        padding-bottom: 0.5rem;
                        p{
                            font-weight: 600;
                        }
                        img {
                            width: 5%;
                            height: 100%;
                            padding-top: 0.2rem;
                        }
                    }
                }


                &:hover {
                    background-color: $white;
                    box-shadow: none;
                    transform: translateY(0);
                    cursor: pointer;

                    h2 {
                        color: $black;
                    }

                    p {
                        color: $black;
                    }
                }

            }
        }


    }

    .solutions {
        position: relative;
        padding: 1.5rem 0 3rem;

        .heading {
            padding-bottom: 10px;

            h1 {
                font-size: 1.3rem;

                &::after {
                    margin-top: 6px;
                }
            }

            ul {
                padding: 0;

                li {
                    list-style-type: disc;
                    font-size: 10px;
                    font-weight: 600;
                    display: inline;
                    padding-right: 0.5rem;

                    i {
                        font-size: 0.5rem;
                    }
                }
            }
        }

        .box {
            padding: 0 1rem;

            .item {
                text-align: center;
                background-color: $white;
                padding:1rem  0.5rem;
                border-radius: 10px;
                box-shadow: 5px 5px 20px #00000007;
                display: flex;
                align-items: center;
                flex-direction: column;
                column-gap: 0.5rem;
                height: 300px;
                .info {
                    text-align: center;

                    h2 {
                        font-size: 16px;
                        font-weight: 700;
                        padding: 10px 0 20px;
                        color: $sec_color;
                    }

                    ul {
                        li {
                            list-style: none;
                            text-align: left;
                            position: relative;
                            padding-left: 1rem;
                            font-size: 12px;
                            padding-bottom: 0.5rem;
                            display: flex;
                            align-items: flex-start;
                            column-gap: 0.5rem;

                            img {
                                width: 5%;
                                height: 100%;
                                padding-top: 0.2rem;
                            }
                            p{
                                margin: 0;
                                font-weight: 600;
                            }
                        }
                    }
                }

                img {
                    width: 40px;
                    height: 40px;
                    border-radius: 0;
                    

                }

            }


        }

    }

    .desktop {
        display: none;
    }

    .mobile {
        display: block;

    }

    .hero {
        padding: 1rem 0 2rem;

        .heading {
            padding-bottom: 10px;

            h1 {
                font-size: 1.3rem;

                &::after {
                    margin-top: 8px;
                }
            }
        }

        .mobile {
            display: block;

            button {
                font-size: 14px;
                font-weight: 600;
                background-color: $white;

                &::after {
                    width: 20px;
                    height: 20px;
                    background-size: 15px;

                }
            }

            ul {
                li {
                    list-style: none;
                    font-size: 12px;
                    font-weight: 600;
                    line-height: 12px*1.5;

                    img {
                        width: 5%;
                        margin-right: 0.5rem;
                    }
                }
            }
        }

        .desktop {
            display: none;

        }
    }

    .explains {
        padding: 1rem 0 1rem;
        .heading {
            padding-bottom: 0px;

            h1 {
                font-size: 1.3rem;

                &::after {
                    margin-top: 8px;
                }
            }
        }

        .content_box {
            width: 100%;

            .health {
                padding: 1rem 0;

                .wrap {

                    ul {
                        display: grid;
                        grid-template-columns: repeat(1, 1fr);
                        row-gap: 1rem;

                        li {
                            .infos {
                                padding-bottom: 0.5rem;

                                img {
                                    width: 5%;
                                }

                                p {
                                    font-size: 12px;
                                    font-weight: 600;
                                }
                            }

                        }

                    }
                }
            }
        }

        .backButton{
            a{
                font-size: 12px;
            }
        }

    }

    .solutionexplains {
        padding: 1rem 0 1rem;

        .heading {
            padding-bottom: 0px;

            h1 {
                font-size: 1.3rem;

                &::after {
                    margin-top: 8px;
                }
            }
        }

        .content_box {
            width: 100%;

            .health {
                padding: 1rem 0;

                .wrap {


                    ul {
                        display: grid;
                        grid-template-columns: repeat(1, 1fr);
                        row-gap: 1rem;

                        li {
                            ul {
                                li {
                                    padding: 0;
                                    font-size: 12px;

                                    .infos {
                                        padding-bottom: 0.5rem;

                                        img {
                                            width: 5%;
                                        }

                                        p {
                                            font-size: 12px;
                                            font-weight: 600;
                                        }
                                    }

                                    ul {
                                        padding-left: 1rem;
                                    }
                                }
                            }

                            h1 {
                                font-size: 16px;
                                line-height: 16px*1.5;
                                padding-bottom: 20px;
                            }

                            p {
                                font-size: 12px;
                            }
                        }

                    }
                }
            }
        }

        .backButton{
            a{
                font-size: 12px;
            }
        }
    }
}
@import "../../Config/Mixin.scss";
@import "../../Config/Variables.scss";

.contact{
    
    padding: 2rem 0;
    background-color:$pry-color;
    text-align: center;
    .bnr_info{
        h2{
            font-size: 14px;
            font-weight: 600;
            text-transform: uppercase;
            letter-spacing: 3px;
            padding-bottom: 10px;
        }
        h1{
            font-size: 48px;
            font-weight: 800;
            letter-spacing: 0.25px;
            padding-bottom: 1rem;
        }
        p{
            font-size: 14px;
            font-weight: 500;
            color: #6e6d6db1;
            margin: 0;
            a{
                color: #000000b1;
            }
        }
    }
}
.usecase{
    padding: 3rem 0 0;
    background-color: #fdb61c20;
   .heading{
    @include heading
   }
   .flex_wrap{
        display: grid;
        grid-template-columns: 1.5fr 1fr;
        column-gap: 5rem;
        padding-bottom: 5rem;
        .content_info{
            h2{
                font-size: 1.5rem;
                font-weight: 700;
                padding-bottom:30px ;
            }
            ul{
                li{
                    list-style: none;
                    font-size: 1.05rem;
                    // line-height: 1.1rem*1.5;
                    font-weight: 600;
                    padding-bottom: 10px;
                    letter-spacing: 0.25px;
                    display: flex;
                    align-items: flex-start;
                    column-gap: 1rem;
                    i{
                        padding-top: 0.5rem;
                    }
                }
            }
        }
        .img{

            img{
                width: 100%;
                transition:all  0.4s ease-in-out;
                filter: drop-shadow(10px 10px 20px #0000003c);
                border-radius: 10px;
                &:hover{
                    transform: scale(1.01);
                    transition:all  0.4s ease-in-out;
                    cursor: pointer;

                }
            }
        }


   }
   .flex_wrap:nth-child(odd){
    padding-top: 2rem;
    direction: rtl;
    .content_info{
        text-align: left;
        ul{
            li{
                flex-direction: row-reverse;
            }
        }

    }

    // .content_info:nth-child(5){
    //  grid-template-columns: 1fr 3fr;
 
    //  .img{
    //      text-align: left;
    //      img{
    //          width: 50%;
    //      }
    //  }
    // }
   }
   .flex_wrap:nth-child(4){
    grid-template-columns: 3fr 1fr;

   }
}

@media screen and  (max-width:1280px ) and (min-width:991px){
    .contact{
        padding: 1rem 0;
        background-color:$pry-color;
        text-align: center;
        .bnr_info{
            h2{
                font-size: 12px;
                font-weight: 600;
                text-transform: uppercase;
                letter-spacing: 3px;
                padding-bottom: 5px;
            }
            h1{
                font-size: 38px;
                font-weight: 800;
                letter-spacing: 0.25px;
                padding-bottom: 0.5rem;
            }
            p{
                font-size: 14px;
                font-weight: 500;
                color: #6e6d6db1;
                margin: 0;
                a{
                    color: #000000b1;
                }
            }
        }
    }
    .usecase{
        padding: 3rem 0 0;
        background-color: #fdb61c20;
       .heading{
        @include heading
       }
       .flex_wrap{
            display: grid;
            grid-template-columns: 1.5fr 1fr;
            column-gap: 3rem;
            padding-bottom: 5rem;
            .content_info{
                h2{
                    font-size: 1.5rem;
                    font-weight: 600;
                    padding-bottom:40px ;
                }
                ul{
                    li{
                        list-style: none;
                        font-size: 0.8rem;
                        font-weight: 600;
                        padding-bottom: 10px;
                        letter-spacing: 0.25px;
                        display: flex;
                        align-items: flex-start;
                        column-gap: 1rem;
                        i{
                            padding-top: 0.5rem;
                        }
                    }
                }
            }
            .img{
    
                img{
                    width: 100%;
                    transition:all  0.4s ease-in-out;
                    filter: drop-shadow(10px 10px 20px #0000003c);
                    border-radius: 10px;
                    &:hover{
                        transform: scale(1.01);
                        transition:all  0.4s ease-in-out;
                        cursor: pointer;
    
                    }
                }
            }
    
    
       }
       .flex_wrap:nth-child(odd){
        padding-top: 2rem;
        direction: rtl;
        .content_info{
            text-align: left;
            ul{
                li{
                    flex-direction: row-reverse;
                }
            }
    
        }
    

       }
       .flex_wrap:nth-child(4){
        grid-template-columns: 3fr 1fr;
    
       }
    }
}

@media screen and (max-width:992px) and (min-width:768px){
    .contact{
    
        padding: 1rem 0;
       background-color:$pry-color;
    
        text-align: center;
        .bnr_info{
            h1{
                font-size: 28px;
                font-weight: 800;
                letter-spacing: 0.25px;
                padding-bottom: 0.5rem;
            }
            p{
                margin: 0;
                font-size: 12px;
                font-weight: 500;
                color: #6e6d6db1;
                a{
                    color: #000000b1;
                }
            }
        }
    }
    .usecase{
        padding: 1rem 0 0;
        background-color: #fdb61c20;
       .heading{
        padding-bottom: 20px;

        h1 {
            font-size: 1.3rem;

            &::after {
                margin-top: 7px;
            }
        }
       }
       .flex_wrap{
            display: grid;
            grid-template-columns: 1fr;
            padding-bottom: 3rem;
            .content_info{
                h2{
                    font-size: 1.3rem;
                    font-weight: 600;
                    padding-bottom:20px ;
                }
                ul{
                    li{
                        list-style: none;
                        font-size: 0.9rem;
                        font-weight: 600;
                        padding-bottom: 3px;
                        letter-spacing: 0.25px;
                        display: flex;
                        align-items: flex-start;
                        column-gap: 0.5rem;
                        i{
                            padding-top: 0.1rem;
                        }
                    }
                }
            }
            .img{
                text-align: left;
                img{
                    width: 60%;
                    transition:all  0.4s ease-in-out;
                    filter: drop-shadow(10px 10px 20px #0000003c);
                    border-radius: 10px;
                    &:hover{
                        transform: scale(1.01);
                        transition:all  0.4s ease-in-out;
                        cursor: pointer;
    
                    }
                }
            }
    
    
       }
       .flex_wrap:nth-child(odd){
        padding-top: 2rem;
        direction: rtl;
        .content_info{
            text-align: left;
            ul{
                li{
                    flex-direction: row-reverse;
                }
            }
    
        }
    
       }
       .flex_wrap:nth-child(4){
        grid-template-columns:  1fr;
    
        .img{
            text-align: left;
        }
        img{
            width: 50%;
        }
       }
    }
}
@media screen and (max-width:767px) and (min-width:448px){
    .contact{
    
        padding: 1rem 0;
       background-color:$pry-color;
    
        text-align: center;
        .bnr_info{
            h1{
                font-size: 28px;
                font-weight: 800;
                letter-spacing: 0.25px;
                padding-bottom: 0.5rem;
            }
            p{
                margin: 0;
                font-size: 12px;
                font-weight: 500;
                color: #6e6d6db1;
                a{
                    color: #000000b1;
                }
            }
        }
    }
    .usecase{
        padding: 1rem 0 0;
        background-color: #fdb61c20;
       .heading{
        padding-bottom: 20px;

        h1 {
            font-size: 1.3rem;

            &::after {
                margin-top: 7px;
            }
        }
       }
       .flex_wrap{
            display: grid;
            grid-template-columns: 1fr;
            padding-bottom: 3rem;
            .content_info{
                h2{
                    font-size: 1rem;
                    font-weight: 600;
                    padding-bottom:20px ;
                }
                ul{
                    li{
                        list-style: none;
                        font-size: 0.7rem;
                        font-weight: 600;
                        padding-bottom: 3px;
                        letter-spacing: 0.25px;
                        display: flex;
                        align-items: flex-start;
                        column-gap: 0.5rem;
                        i{
                            padding-top: 0.1rem;
                        }
                    }
                }
            }
            .img{
                text-align: left;
                img{
                    width: 60%;
                    transition:all  0.4s ease-in-out;
                    filter: drop-shadow(10px 10px 20px #0000003c);
                    border-radius: 10px;
                    &:hover{
                        transform: scale(1.01);
                        transition:all  0.4s ease-in-out;
                        cursor: pointer;
    
                    }
                }
            }
    
    
       }
       .flex_wrap:nth-child(odd){
        padding-top: 2rem;
        direction: rtl;
        .content_info{
            text-align: left;
            ul{
                li{
                    flex-direction: row-reverse;
                }
            }
    
        }
    
       }
       .flex_wrap:nth-child(4){
        grid-template-columns:  1fr;
    
        .img{
            text-align: left;
        }
        img{
            width: 60%;
        }
       }
    }
}
@media screen and (max-width:447px) and (min-width:376px){
    .contact{
    
        padding: 1rem 0;
       background-color:$pry-color;
    
        text-align: center;
        .bnr_info{
            h1{
                font-size: 28px;
                font-weight: 800;
                letter-spacing: 0.25px;
                padding-bottom: 0.5rem;
            }
            p{
                margin: 0;
                font-size: 12px;
                font-weight: 500;
                color: #6e6d6db1;
                a{
                    color: #000000b1;
                }
            }
        }
    }
    .usecase{
        padding: 1rem 0 0;
        background-color: #fdb61c20;
       .heading{
        padding-bottom: 20px;

        h1 {
            font-size: 1.3rem;

            &::after {
                margin-top: 7px;
            }
        }
       }
       .flex_wrap{
            display: grid;
            grid-template-columns: 1fr;
            padding-bottom: 3rem;
            .content_info{
                h2{
                    font-size: 1rem;
                    font-weight: 600;
                    padding-bottom:20px ;
                }
                ul{
                    li{
                        list-style: none;
                        font-size: 0.7rem;
                        font-weight: 600;
                        padding-bottom: 3px;
                        letter-spacing: 0.25px;
                        display: flex;
                        align-items: flex-start;
                        column-gap: 0.5rem;
                        i{
                            padding-top: 0.1rem;
                        }
                    }
                }
            }
            .img{
                text-align: left;
                img{
                    width: 60%;
                    transition:all  0.4s ease-in-out;
                    filter: drop-shadow(10px 10px 20px #0000003c);
                    border-radius: 10px;
                    &:hover{
                        transform: scale(1.01);
                        transition:all  0.4s ease-in-out;
                        cursor: pointer;
    
                    }
                }
            }
    
    
       }
       .flex_wrap:nth-child(odd){
        padding-top: 2rem;
        direction: rtl;
        .content_info{
            text-align: left;
            ul{
                li{
                    flex-direction: row-reverse;
                }
            }
    
        }
    
       }
       .flex_wrap:nth-child(4){
        grid-template-columns:  1fr;
    
        .img{
            text-align: left;
        }
        img{
            width: 60%;
        }
       }
    }
}
@media screen and (max-width:375px) and (min-width:320px){
    .contact{
    
        padding: 1rem 0;
       background-color:$pry-color;
    
        text-align: center;
        .bnr_info{
            h1{
                font-size: 28px;
                font-weight: 800;
                letter-spacing: 0.25px;
                padding-bottom: 0.5rem;
            }
            p{
                margin: 0;
                font-size: 12px;
                font-weight: 500;
                color: #6e6d6db1;
                a{
                    color: #000000b1;
                }
            }
        }
    }
    .usecase{
        padding: 1rem 0 0;
        background-color: #fdb61c20;
       .heading{
        padding-bottom: 20px;

        h1 {
            font-size: 1.3rem;

            &::after {
                margin-top: 7px;
            }
        }
       }
       .flex_wrap{
            display: grid;
            grid-template-columns: 1fr;
            padding-bottom: 2rem;
            .content_info{
                h2{
                    font-size: 1rem;
                    font-weight: 600;
                    padding-bottom:20px ;
                }
                ul{
                    li{
                        list-style: none;
                        font-size: 0.7rem;
                        // line-height: 1.1rem*1.5;
                        font-weight: 600;
                        padding-bottom: 3px;
                        letter-spacing: 0.25px;
                        display: flex;
                        align-items: flex-start;
                        column-gap: 0.5rem;
                        i{
                            padding-top: 0.1rem;
                        }
                    }
                }
            }
            .img{
                text-align: left;
                img{
                    width: 70%;
                    transition:all  0.4s ease-in-out;
                    filter: drop-shadow(10px 10px 20px #0000003c);
                    border-radius: 10px;
                    &:hover{
                        transform: scale(1.01);
                        transition:all  0.4s ease-in-out;
                        cursor: pointer;
    
                    }
                }
            }
    
    
       }
       .flex_wrap:nth-child(odd){
        padding-top: 2rem;
        direction: rtl;
        .content_info{
            text-align: left;
            ul{
                li{
                    flex-direction: row-reverse;
                }
            }
    
        }
    
       }
       .flex_wrap:nth-child(4){
        grid-template-columns:  1fr;
    
        .img{
            text-align: left;
        }
        img{
            width: 50%;
        }
       }
    }
}